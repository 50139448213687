import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import {
  getTeamMembersData,
  inviteMember,
} from "~/redux/actions/user-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { EMAIL_REGEX } from "~/utils/constants";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, InputLabel, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const InviteModal = (props) => {
  const { openModal, handleCloseModal } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL)
      .required(MESSAGES.AUTH.EMAIL_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const invite = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const response = await dispatch(
        inviteMember({ email: formik.values.email })
      );
      if (response.status === 200) {
        dispatch(success(response.data.message));
        dispatch(getTeamMembersData());
        onClose();
      } else {
        dispatch(
          failure(
            response.data.data?.errors?.length
              ? response.data.data?.errors[0].msg
              : response.data?.message
          )
        );
      }
    } catch (error) {
      return error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    formik.touched.email = false;
  }, [openModal]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="inviteUserModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.USERS.INVITE_USER.TITLE}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={invite}>
            <Box
              sx={{
                maxWidth: "564px",
                marginInline: "auto",
                textAlign: "start",
                marginTop: "16px",
              }}
            >
              <InputLabel htmlFor="Email Address" className="inputLabel">
                {MESSAGES.ACCOUNT.USERS.INVITE_USER.EMAIL_LABEL}
              </InputLabel>
              <TextField
                placeholder={MESSAGES.FORGOT_PASSWORD.EMAIL_PLACEHOLDER}
                fullWidth
                name="email"
                className={
                  formik.errors.email && formik.touched.email
                    ? "invalid formInput inputField"
                    : "inputField"
                }
                onChange={formik.handleChange}
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.email}
                autoComplete="off"
                sx={{
                  "& input::placeholder": {
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  },
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              {formik.touched.email && formik.errors.email && (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.email}
                </Typography>
              )}
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.ACCOUNT.USERS.INVITE_USER.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={() => validateForm(formik)}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.USERS.INVITE_USER.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default InviteModal;
