import React from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { MESSAGES } from "~/utils/message.js";

// MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// Icons
import { OrderSubmitCIcon, OrderCancelCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SubmitModal = (props) => {
  const navigate = useNavigate();

  const {
    handleClose,
    open,
    onSubmit,
    modalType,
    contactType,
    deliverable,
    hasInsufficientFunds = false,
  } = props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={
        contactType === "Upload"
          ? "submitModalWrapper upload"
          : "submitModalWrapper"
      }
      PaperProps={{
        sx: {
          maxHeight: hasInsufficientFunds ? "300px" : "246px",
          maxWidth: hasInsufficientFunds ? "550px" : "442px",
        },
      }}
    >
      <DialogContent className="submitModalContent" dividers>
        <Box className="submitHeader">
          <img
            src={modalType === "submit" ? OrderSubmitCIcon : OrderCancelCIcon}
            alt="submit-icon"
          />
        </Box>
        <Box className="submitContent">
          <Typography>
            {modalType === "submit"
              ? hasInsufficientFunds
                ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.TITLE_INSUFFICIENT_FUNDS
                : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.TITLE
              : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_TITLE}
          </Typography>
          <Box className="typoContent">
            <Typography
              className={
                modalType === "submit" ? `submitHeading` : `notSubmitHeading`
              }
            >
              {modalType === "submit"
                ? hasInsufficientFunds
                  ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL
                      .HEADING_INSUFFICIENT_FUNDS
                  : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.HEADING
                : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_HEADING}
            </Typography>
            {modalType === "submit" && contactType === "Upload" ? (
              <Typography className="notSubmitSubHeading">
                {deliverable === 1
                  ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.SINGLE_DESCRIPTION
                  : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.DESCRIPTION}
              </Typography>
            ) : (
              ""
            )}
            {modalType === "cancel" ? (
              <Typography className="notSubmitSubHeading">
                {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_DESCRIPTION}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box className="submitModalBtns">
          {modalType === "cancel" && (
            <Button onClick={handleClose}>
              {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.BACK_BUTTON}
            </Button>
          )}
          {hasInsufficientFunds ? (
            <>
              <Button onClick={onSubmit}>
                {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CLOSE}
              </Button>
              <Button
                className="goToWalletBtn"
                onClick={() => {
                  navigate("/account/wallet");
                }}
              >
                {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.GO_TO_WALLET}
              </Button>
            </>
          ) : (
            <Button
              onClick={onSubmit}
              className={
                modalType === "submit" ? `okSubmitBtn` : `cancelSubmitBtn`
              }
            >
              {modalType === "submit"
                ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.SUBMIT_BUTTON
                : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_BUTTON}
            </Button>
          )}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default SubmitModal;
