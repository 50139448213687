import React, { useEffect, useMemo, useState } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";

// Actions
import {
  fetchContacts,
  updateContactTags,
} from "~/redux/actions/contacts-actions";
import { failure, success } from "~/redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";

// Components
import CreateTagModal from "~/components/Settings/Tags/CreateTag/index.jsx";
import GenericAutocomplete from "~/components/General/Autocomplete";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Chip, CircularProgress } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Icons
import { ModalCrossCIcon, PlusIconCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TagsUpdate = (props) => {
  const {
    handleClose,
    open,
    header,
    selectedRows,
    setSelectedRows,
    type,
    selectedTags,
    setSelectedTags,
    search,
    applyFilters,
    filters,
    pagination,
  } = props;

  const [loading, setLoading] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [searchAutocomplete, setSearchAutocomplete] = useState("");

  const [filteredOptions, setFilteredOptions] = useState([]);

  const dispatch = useDispatch();

  const tags = useSelector((state) => state.contactsReducers.labels);

  const memoizedOptions = useMemo(() => {
    const baseOptions = tags
      .filter((tag) => tag.id !== -1)
      .map((tag) => ({ id: tag.id, label: tag.title }));

    if (type === "update") {
      baseOptions.push({
        id: "-1",
        label: "Create New Tag",
        custom: true,
        icon: PlusIconCIcon,
        func: () => setCreateTagModal(true),
      });
    }

    return baseOptions;
  }, [tags, type]);

  const handleTagsSelection = (value) => {
    const createNewTagOption = value.find((tag) => tag.id === "-1");
    if (createNewTagOption) {
      setCreateTagModal(true);
    } else {
      setSelectedTags(value);
    }
  };

  const getContacts = () => {
    let payload = {
      pagination,
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchContacts(payload));
  };

  const handleUpdateTags = async () => {
    setLoading(true);

    const selectedContacts = selectedRows.map((row) => Number(row)).join(",");
    const selectedTagIds = selectedTags.map((tag) => tag.id).join(",");
    const payload = {
      contactIds: selectedContacts,
      tagIds: selectedTagIds,
      action: type === "update" ? "add" : "remove",
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    const updateTags = await dispatch(updateContactTags(payload));
    if (updateTags.status === 200) {
      getContacts();
      dispatch(success(MESSAGES.CONTACTS.TAGS_UPDATE_SUCCESS_MESSAGE));
      setSelectedRows([]);
      setSelectedTags([]);
      handleClose();
      setLoading(false);
    } else {
      dispatch(
        failure(
          updateTags?.data?.message && MESSAGES.CONTACTS.TAGS_UPDATE_ERROR
        )
      );
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchAutocomplete(value);

    let filteredResults = memoizedOptions.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );

    if (filteredResults.length === 0 && value.trim() !== "") {
      // If no match found, add "Create New Tag" option
      filteredResults =
        type === "update"
          ? [
              {
                id: "-1",
                label: `Create New Tag`,
                custom: true,
                icon: PlusIconCIcon,
                func: () => setCreateTagModal(true),
              },
            ]
          : [];
    }

    setFilteredOptions(filteredResults);
  };

  useEffect(() => {
    handleSearch(searchAutocomplete);
  }, [searchAutocomplete, memoizedOptions]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="tagsUpdateModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="tagsUpdateModalContent" dividers>
          <Box className="tagsUpdateModalHeader">
            <Typography gutterBottom variant="h4">
              {header}
            </Typography>
          </Box>
          <Box className="tagsUpdateSelect">
            <GenericAutocomplete
              options={filteredOptions}
              setSelectedValue={handleTagsSelection}
              placeholder={type === "update" ? MESSAGES.CONTACTS.TAGS_ADD_PLACEHOLDER : MESSAGES.CONTACTS.TAGS_REMOVE_PLACEHOLDER}
              noOptionText={MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_SEARCH_TAG}
              selectedValue={
                typeof selectedTags === "string" ? null : selectedTags
              }
              search={setSearchAutocomplete}
              updateErrors={() => {}}
              name={"contacts"}
              loading={loading}
              multiple={true}
              tagsClass={true}
            />
            {selectedTags.length > 0 && (
              <Box className="selectedTagsWrapper">
                {selectedTags.map((value, index) => {
                  return (
                    <Box className="tagContent" key={index}>
                      <Chip
                        className="tagChip"
                        key={index}
                        label={value.label}
                        onDelete={() =>
                          setSelectedTags(
                            selectedTags.filter((v) => v !== value)
                          )
                        }
                        deleteIcon={<HighlightOffIcon />}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box className="tagsUpdateModalBtns">
            <Button onClick={handleClose}>
              {MESSAGES.CONTACTS.DELETE.CANCEL_BUTTON}
            </Button>
            <Button onClick={handleUpdateTags} disabled={loading}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.CONTACTS.TAGS_UPDATE_BTN_TEXT
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      <CreateTagModal
        openModal={createTagModal}
        handleCloseModal={() => setCreateTagModal(false)}
        setSelectedTags={(newTag) => {
          setSelectedTags((prevTags) => [
            ...prevTags,
            ...newTag.map((tag) => ({ id: tag.id, label: tag.title })),
          ]);
        }}
        tagsClass={true}
      />
    </>
  );
};

export default TagsUpdate;
