import React from "react";

// Utils
import { dateFormat } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";
import { getEvent, isSlackWebhook } from "~/utils/helperFunctions";

// MUI Components
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

// Icons
import { BackCIcon } from "~/assets/images";

// Styles
import "./styles.scss";
import JsonRenderer from "./JsonRenderer";

const WebhookLog = ({ selectedWebhookLog, setDisplayLogPage }) => {
  const eventTitle = getEvent(selectedWebhookLog) || " -";
  const statusCode =
    selectedWebhookLog?.responseStatusCode ||
    selectedWebhookLog?.value?.response?.status ||
    " -";
  const formattedDate = dateFormat(selectedWebhookLog?.createdAt) || " -";

  const requestBody = selectedWebhookLog?.value?.request?.body;
  const requestJson =
    isSlackWebhook(selectedWebhookLog?.value?.request?.url) && requestBody?.text
      ? JSON.parse(requestBody.text)
      : requestBody;

  const responseJson = selectedWebhookLog?.value?.response?.data;

  return (
    <Container maxWidth="xxl">
      <Grid container className="webhookLogGrid">
        <Grid item lg={1} md={1} sm={2} xs={2}>
          <Button
            className="webhookBackBtn"
            onClick={() => setDisplayLogPage(false)}
          >
            <img src={BackCIcon} alt="icon" />
            <Typography>
              {MESSAGES.SETTINGS.WEBHOOKS.LOGS.BACK_BUTTON}
            </Typography>
          </Button>
        </Grid>
        <Grid item lg={11} md={11} sm={9} xs={9} sx={{ pl: 3 }}>
          <Box className="detailsHeading">
            <Typography>{MESSAGES.SETTINGS.WEBHOOKS.LOGS.TITLE}</Typography>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.EVENT_TITLE}
              </Typography>
              <Typography>{eventTitle}</Typography>
            </Box>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.STATUS_TITLE}
              </Typography>
              <Typography>{statusCode}</Typography>
            </Box>
            <Box className="detailsWrapper">
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.LOGS.DATE_TITLE}
              </Typography>
              <Typography>{formattedDate}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider className="webhookLogDivider" />

      <Grid container>
        <Grid item lg={1} />
        <Grid item lg={11} md={11} sm={12} xs={12} sx={{ pl: 3 }}>
          {requestJson && (
            <JsonRenderer title="Request Body" json={requestJson} />
          )}
          {responseJson && (
            <JsonRenderer title="Response Data" json={responseJson} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebhookLog;
