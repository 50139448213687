import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import { getUserData } from "~/redux/actions/auth-actions";

// Utils
import request from "~/utils/request";
import { MESSAGES } from "~/utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  Box,
  InputLabel,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflow: "hidden",
    border: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditAccountModal = (props) => {
  const { user, openModal, handleCloseModal } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    company: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.COMPANY_LIMIT),
    address1: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LIMIT),
    address2: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_2_LIMIT),
    city: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LIMIT),
    state: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LIMIT),
    zip: yup
      .string()
      .max(10, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      company: user.companyName || "",
      address1: user.address1 || "",
      address2: user.address2 || "",
      city: user.city || "",
      state: user.state || "",
      zip: user.zip || "",
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const updateAccountInfo = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const response = await request.post("/users/update-info", formik.values);
      dispatch(success(response.data.message));
      dispatch(getUserData());
      handleCloseModal();
    } catch (error) {
      dispatch(
        failure(
          error.response?.data?.message ||
            error?.data?.message ||
            MESSAGES.GENERAL_ERROR
        )
      );
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.company = false;
    formik.touched.address1 = false;
    formik.touched.address2 = false;
    formik.touched.city = false;
    formik.touched.state = false;
    formik.touched.zip = false;
    formik.initialValues.company = user.companyName || "";
    formik.initialValues.address1 = user.address1 || "";
    formik.initialValues.address2 = user.address2 || "";
    formik.initialValues.city = user.city || "";
    formik.initialValues.state = user.state || "";
    formik.initialValues.zip = user.zip || "";
  }, [openModal]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="editAccountModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <form className="updateFormWrapper" onSubmit={updateAccountInfo}>
          <DialogContent className="exportModalContent" dividers>
            <Box className="exportHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.TITLE}
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "5px", marginBottom: "25px" }}
            >
              {/* Column 1 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="Company" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.COMPANY_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.COMPANY_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="company"
                  className={
                    formik.errors.company && formik.touched.company
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.company.trimStart()}
                />
                {formik.touched.company && formik.errors.company ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.company}
                  </Typography>
                ) : null}
              </Grid>

              {/* Column 2 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="Address Line 1" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="address1"
                  className={
                    formik.errors.address1 && formik.touched.address1
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.address1.trimStart()}
                />
                {formik.touched.address1 && formik.errors.address1 ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.address1}
                  </Typography>
                ) : null}
              </Grid>

              {/* Add more columns as needed */}
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: "25px" }}>
              {/* Column 2 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="Address Line 2" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_2_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_2_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="address2"
                  className={
                    formik.errors.address2 && formik.touched.address2
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.address2.trimStart()}
                />
                {formik.touched.address2 && formik.errors.address2 ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.address2}
                  </Typography>
                ) : null}
              </Grid>

              {/* Column 2 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="City" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="city"
                  className={
                    formik.errors.city && formik.touched.city
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.city.trimStart()}
                />
                {formik.touched.city && formik.errors.city ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.city}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Column 3 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="State" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="state"
                  className={
                    formik.errors.state && formik.touched.state
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.state.trimStart()}
                />
                {formik.touched.state && formik.errors.state ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.state}
                  </Typography>
                ) : null}
              </Grid>

              {/* Column 2 */}
              <Grid item xs={6}>
                <InputLabel htmlFor="Zip Code" className="inputLabel">
                  {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LABEL}
                </InputLabel>
                <TextField
                  placeholder={
                    MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LABEL
                  }
                  fullWidth
                  onChange={formik.handleChange}
                  autoComplete="off"
                  name="zip"
                  className={
                    formik.errors.zip && formik.touched.zip
                      ? "inputField invalid"
                      : "inputField"
                  }
                  onBlur={realTimeValidation ? formik.handleBlur : undefined}
                  onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                  value={formik.values.zip.trimStart()}
                />
                {formik.touched.zip && formik.errors.zip ? (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.zip}
                  </Typography>
                ) : null}
              </Grid>

              {/* Add more columns as needed */}
            </Grid>

            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CANCEL_BUTTON}
              </Button>
              <Button className="btnSave" onClick={updateAccountInfo} disabled={loading}>
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </BootstrapDialog>
    </>
  );
};

export default EditAccountModal;
