import React, { useState } from "react";
import Papa from "papaparse";

//Hooks
import { useDispatch } from "react-redux";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";
import request from "~/utils/request";

// Components
import OffersModal from "../OffersModal";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, FormControl, Grid, CircularProgress } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";

// Icons
import {
  ModalCrossCIcon,
  GreenTickCIcon,
  RedCrossCIcon,
} from "~/assets/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Styles
import "./styles.scss";
import { isOfferGeneratorWebsite } from "~/utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OfferImportModal = (props) => {
  const {
    handleClose,
    open,
    mappingFileds,
    options,
    updateMapping,
    selectedFile,
    offerPercentage = false,
    loadOffers,
    setLoadOffers,
  } = props;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (params, event) => {
    const updatedData = mappingFileds.map((item) => {
      if (item.label === params) {
        return { ...item, value: event.target.value, error: false };
      }
      return item;
    });
    updateMapping(updatedData);
  };

  const handleClearSelection = (params) => {
    const clearSelction = mappingFileds.map((item) => {
      if (item.label === params) {
        return { ...item, value: "" };
      }
      return item;
    });
    updateMapping(clearSelction);
  };

  const validateFields = () => {
    let inValids = [];
    const validatedData = mappingFileds.map((item) => {
      if (item.required && !item.value) {
        inValids.push(item.label);
        return { ...item, error: true };
      }
      return item;
    });
    updateMapping(validatedData);
    if (inValids.length) {
      setErrors(inValids);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  };

  const getCsvRows = async (headers) => {
    return new Promise((resolve) => {
      Papa.parse(selectedFile[0], {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: async function (results) {
          try {
            const finalData = [];

            const propAddressHeader = headers.find(
              (header) => header.dbColumn === "propertyAddress"
            )?.csvColumn;
            const propCityHeader = headers.find(
              (header) => header.dbColumn === "propertyCity"
            )?.csvColumn;
            const propStateHeader = headers.find(
              (header) => header.dbColumn === "propertyState"
            )?.csvColumn;
            const propZipHeader = headers.find(
              (header) => header.dbColumn === "propertyZip"
            )?.csvColumn;

            for (const row of results.data) {
              const propAddress = row[propAddressHeader];
              const propCity = row[propCityHeader];
              const propState = row[propStateHeader];
              const propZip = row[propZipHeader];

              if (propAddress && propCity && propState && propZip) {
                const address = `${propAddress}, ${propCity}, ${propState} ${propZip}`;

                finalData.push({
                  address,
                });
              }

              if (finalData.length >= MESSAGES.ORDERS.CREATE.MAX_RECORDS) {
                break;
              }
            }

            resolve(finalData);
          } catch (err) {
            resolve([]);
          }
        },
      });
    });
  };

  const processCsv = async () => {
    try {
      const isValid = validateFields();
      if (isValid) {
        let headers = mappingFileds.map((item) => {
          return {
            dbColumn: item.dbColumn,
            csvColumn: item.value,
          };
        });
        const addresses = await getCsvRows(headers);
        setLoading(true);
        const response = await request.post(
          "property-addresses/fetch-estimates",
          {
            addresses,
          }
        );
        setAddresses(response.data.data.addresses);
        setLoadOffers(true);
      }
    } catch (error) {
      console.log(error);
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleCloseBtn = () => {
    handleClose();
    setTimeout(() => {
      setLoadOffers(false);
    }, 500);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseBtn}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="importModalWrapper"
        sx={{
          "& .MuiPaper-root": {
            maxWidth: loadOffers ? "1200px" : "776px",
            minHeight: loadOffers || offerPercentage ? "auto" : "600px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseBtn}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="importModalContent" dividers>
          <Box className="importModalHeader">
            <Typography variant="h4">
              {loadOffers
                ? "Find Your Offer Percentage"
                : MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.TITLE}
            </Typography>
          </Box>
          <Box className="mainContent">
            {!loadOffers && (
              <Box className="importModalDetails">
                <Grid container className="detailsHeader">
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    <Typography>
                      {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.MATCH_LABEL}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Typography>
                      {isOfferGeneratorWebsite()
                        ? MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.OUR_LABEL
                        : MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.OLC_LABEL}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography>
                      {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.CSV_LABEL}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Typography>
                      {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.SAMPLE_LABEL}
                    </Typography>
                  </Grid>
                </Grid>
                <Box className={`detailsRow ${!loadOffers && "noScroll"}`}>
                  {mappingFileds.map((content, index) => {
                    return (
                      <Grid
                        container
                        className={`dataRow ${
                          content.value
                            ? `verfied`
                            : content.error &&
                              errors.some((str) => str.includes(content.label))
                            ? `mapingError`
                            : `notVerfied`
                        }`}
                        key={index}
                      >
                        <Grid item lg={1} md={1} sm={1} xs={1}>
                          <img
                            src={content.value ? GreenTickCIcon : RedCrossCIcon}
                            alt="green"
                          />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                          <Typography>
                            {content.label}
                            {content.required
                              ? MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING
                                  .REQUIRED
                              : null}
                          </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                          <FormControl>
                            <Select
                              id="demo-multiple-checkbox"
                              displayEmpty
                              value={content.value}
                              onChange={(e) => handleChange(content.label, e)}
                              inputProps={{
                                IconComponent: () => (
                                  <Box className="selectActions">
                                    {content.value && (
                                      <CloseIcon
                                        onClick={() => {
                                          handleClearSelection(content.label);
                                        }}
                                      />
                                    )}
                                    <KeyboardArrowDownIcon />
                                  </Box>
                                ),
                              }}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return (
                                    <Typography
                                      sx={{ opacity: "0.5", fontSize: "14px" }}
                                    >
                                      {content.label}
                                    </Typography>
                                  );
                                }
                                return selected;
                              }}
                              MenuProps={MenuProps}
                              className="statuSelect"
                            >
                              {options.map(({ name }, id) => (
                                <MenuItem key={id} value={name}>
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                          <Box className="sampleDataWrapper">
                            {options.map((item, id) =>
                              item.name.trim() === content.value ? (
                                <Typography key={id + "x"}>
                                  {item.examples[0]}
                                  <br />
                                  {item.examples[1]}
                                </Typography>
                              ) : null
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Box className="contactsErrorWrapper">
                    {errors &&
                      errors.map((content, id) => {
                        return (
                          <Typography
                            key={id + "errors"}
                            className="errorFields"
                          >
                            {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.REQUIRED}
                            {content}
                            {
                              MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING
                                .REQUIRED_TEXT
                            }
                          </Typography>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            )}

            {loadOffers && <OffersModal addresses={addresses} />}

            <Box className="importModalBtns">
              <Button onClick={handleCloseBtn}>
                {!loadOffers
                  ? MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.CANCEL_BUTTON
                  : MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.CLOSE_BUTTON}
              </Button>
              {!loadOffers && (
                <Button onClick={processCsv} disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : !loadOffers ? (
                    MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.SUBMIT_BUTTON
                  ) : (
                    "Choose Offer"
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default OfferImportModal;
