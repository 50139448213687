// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_CONTACT_IMPORT_LOADER, SET_CONTACT_IMPORTS_DATA } from "./action-types";
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { dateFormat } from "~/utils/date-format";

const fetchContactImports = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_CONTACT_IMPORT_LOADER, payload: true });

        let url = 'contact-import-logs';

        let queryPayload = {
            page: payload?.page ? payload.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pageSize ? payload.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        const response = await request.get(url, queryPayload);
        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((contactImport) => ({
                ...contactImport,
                createdAt: dateFormat(contactImport.createdAt),
            }));
            const contactImports = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
            }
            dispatch({ type: SET_CONTACT_IMPORTS_DATA, payload: contactImports });
        }

        return response;
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_CONTACT_IMPORT_LOADER, payload: false });
    }
}

export { fetchContactImports };
