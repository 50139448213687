import React, { useState, useEffect } from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { displayTooltip } from "~/hooks/displayTooltip";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import { getFullName, stringLength } from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Chip,
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  Menu,
  Checkbox,
  IconButton,
} from "@mui/material";

// Icons
import { NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";

// Styles
import "./styles.scss";

const ContactsTable = ({
  pagination,
  updatePagination,
  setSelectedRows,
  selectedRows,
  searchApplied
}) => {
  const [expandRow, setShowMoreRows] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const pageSizes = DEFAULT_PAGE_SIZES;
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const fetchedRows = useSelector((state) => state.contactsReducers.contacts);
  const rowsCount = useSelector((state) => state.contactsReducers.rowsCount);
  const to = useSelector((state) => state.contactsReducers.to);
  const from = useSelector((state) => state.contactsReducers.from);
  const lastPage = useSelector((state) => state.contactsReducers.lastPage || 1);
  const currentPage = useSelector(
    (state) => state.contactsReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.contactsReducers.perPage || DEFAULT_PAGINATION.pageSize
  );
  const isLoading = useSelector((state) => state.contactsReducers.loading);

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
    setBulkSelect(false);
  };

  const handleRowClick = (params, event) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(`/contacts/${params.id}`, "_blank");
    } else {
      navigate(`/contacts/${params.id}`);
    }
  };

  const handleRowHeight = (params) => {
    // Adjust the base row height and add additional height if the row is expanded
    const baseHeight = 50; // Set your base row height here
    const additionalHeight = expandRow.includes(params.id) ? 150 : 0; // Set the additional height when expanded

    return baseHeight + additionalHeight;
  };

  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    setSelectedRows(selectedRowIds);
  };

  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();

    // Update the selectedRow object directly in the rows array
    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id ? { ...row, checked: !row.checked } : row
    );

    // If the user selects all contacts and then removes a single contact, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.id === selectedRow.id
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );

      const filteredRowsIds = filteredRows
        .map((contact) => contact.checked === true && contact.id)
        .filter(Boolean);

      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.id)
      ? selectedRows.filter((id) => id !== selectedRow.id) // Remove the ID if already selected
      : [...selectedRows, selectedRow.id]; // Add the ID if not selected

    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);

    setRemoveBulkSelect(true);
  };

  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };

  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const isTooltip = displayTooltip();

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
          >
            <MenuItem
              onClick={rowsCount > 0 ? selectAll : undefined}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.CONTACTS.SELECT_ALL_ITEMS} ({rowsCount})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.CONTACTS.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.CONTACTS.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "firstName",
      headerName: "Full Name",
      width: 190,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.firstName}` + `${params.row.lastName}`, 20) ? (
          <Tooltip
            title={getFullName(params?.row?.firstName + " " + params?.row?.lastName)}
            disableHoverListener={isTooltip}
          >
            <Box className="fullNameWrapper">
              <Typography>
                {getFullName(params?.row?.firstName + " " + params?.row?.lastName)}
              </Typography>
              {params.row.isReturnAddress && (
                <Chip label="Return" color="primary" className="returnAddressChip" />
              )}
            </Box>
          </Tooltip>
        ) : (
          <Box className="fullNameWrapper" display="flex" alignItems="center">
            <Typography>
              {getFullName(params?.row?.firstName + " " + params?.row?.lastName)}
            </Typography>
            {params.row.isReturnAddress && (
                <Chip label="Return" color="primary" className="returnAddressChip"/>
              )}
          </Box>
        ),
    },
    {
      field: "companyName",
      headerName: "Company",
      width: 190,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.companyName}`) ? (
          <Tooltip
            title={params.row.companyName}
            disableHoverListener={isTooltip}
          >
            <Box className="valueWrapper">
              <Typography>{params.row.companyName || ' -'}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="valueWrapper">
             <Typography>{params.row.companyName || ' -'}</Typography>
          </Box>
        ),
    },
    {
      field: "address1",
      headerName: "Full Address",
      type: "number",
      width: 240,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(
          `${params.row.address1}` +
            `${params.row.address2}` +
            `${params.row.city}` +
            `${params.row.state}` +
            `${params.row.zip}`,
          56
        ) ? (
          <Tooltip
            title={
              <div>
                {params.row.address1 + " " + params.row.address2}
                <br />
                {params.row.city +
                  ", " +
                  params.row.state +
                  " " +
                  params.row.zip}
              </div>
            }
            disableHoverListener={isTooltip}
          >
            <Box className="addressWrapper">
              <Typography>
                {params.row.address1 + " " + params.row.address2}
              </Typography>
              <Typography>
                {params.row.city +
                  ", " +
                  params.row.state +
                  " " +
                  params.row.zip}
              </Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>
              {params.row.address1 + " " + params.row.address2}
            </Typography>
            <Typography>
              {params.row.city + ", " + params.row.state + " " + params.row.zip}
            </Typography>
          </Box>
        ),
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 260,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          {params.value.length
            ? params.value
                .slice(
                  0,
                  screenWidth < 1600
                    ? 2
                    : expandRow.includes(params.row.id)
                    ? params.value.length
                    : 2
                )
                .map((tag, index) => (
                  <Tooltip title={tag} key={index}>
                    <Chip
                      className={
                        expandRow.includes(params.row.id) ||
                        params.value.length < 3
                          ? "singleTag"
                          : "tagChips"
                      }
                      sx={{
                        maxWidth: "100px !important"
                      }}
                      key={index}
                      label={
                        !expandRow.includes(params.row.id) && tag.length > 25
                          ? tag.slice(0, 25) + `...`
                          : tag
                      }
                      color="primary"
                    />
                  </Tooltip>
                ))
            : " -"}
          {params.value.length > 2 && (
            <Chip
              className="tagChips maxWidth"
              label={`+ ${params.value.length - 2}`}
              color="primary"
            />
          )}
        </Box>
      ),
    },
    {
      field: "addressStatus",
      headerName: "Address Status",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valueWrapper">
          <Typography>{params?.row?.addressStatus || ' -'}</Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valueWrapper">
          <Typography>{params?.row?.createdAt || ' -'}</Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setSelectedRows([]);
  }, [currentPage, perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="tableWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={[20, 50, 100]}
        rowCount={rowsCount}
        pagination={pagination}
        paginationMode="server"
        onRowClick={handleRowClick}
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        getRowHeight={handleRowHeight}
        disableColumnMenu={true}
        className="contactsTableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {`${MESSAGES.NO_RESULTS_TEXT} ${!searchApplied ? "filters" : "search"}`}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Select
          className={
            perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
          }
          value={perPage}
          IconComponent={() => (
            <KeyboardArrowDownIcon
              sx={{ position: "absolute", right: "5px", zIndex: 1 }}
            />
          )}
        >
          {pageSizes.map((pageSize) => {
            return (
              <MenuItem
                key={pageSize}
                value={pageSize}
                onClick={() => {
                  updatePagination({
                    page: 0,
                    pageSize: pageSize,
                  });
                  setBulkSelect(false);
                }}
              >
                {pageSize}
              </MenuItem>
            );
          })}
        </Select>

        <Typography>
          Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to} of{" "}
          {rowsCount} results
        </Typography>
      </Box>
    </div>
  );
};

export default ContactsTable;
