import React, { useEffect, useState } from "react";

// Hooks
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminQueries } from "~/redux/actions/admin-query-actions.js";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants.js";

// Components
import QueriesListing from "./QueryListing";
import NoQueriesScreen from "./NoQueriesScreen";

// MUI Components
import {
  Box,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";

// Icons
import Search from "../../../assets/images/templates/search.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styles
import "./styles.scss";

const DesignerQueries = () => {
  const { designerQueryId } = useParams();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isMounted, setIsMounted] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const queries = useSelector((state) => state.adminQueriesReducers.queries);
  const totalQueriesInDB = useSelector(
    (state) => state.adminQueriesReducers.totalQueriesInDB
  );

  const getAdminQueries = (designerQueryId) => {
    let payload = {
      pagination,
      search: designerQueryId ? designerQueryId : search.length ? search : null,
    };
    dispatch(fetchAdminQueries(payload));
  };

  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const noQueriesInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoQueriesScreen />
      </Container>
    );
  };

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminQueries();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    setIsMounted(true);
    designerQueryId && setSearch(designerQueryId);
    designerQueryId && setSearchApplied(true);
    designerQueryId ? getAdminQueries(designerQueryId) : getAdminQueries();
  }, []);

  useEffect(() => {
    if (isMounted) {
      getAdminQueries();
    }
  }, [pagination.page, pagination]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminQueries();
    }
  }, [search]);

  if (totalQueriesInDB < 1 && totalQueriesInDB !== null) {
    return noQueriesInDb();
  }

  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12} className="searchBtnWrapper">
          <Box className="searchWrapper">
            {/* Search Input */}
            <Input
              className={`searchInput ${searchApplied ? "searchApplied" : ""}`}
              type="text"
              autoComplete="off"
              value={search}
              name="search"
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearch(e.target.value.trimStart())}
              placeholder={MESSAGES.ADMIN_DASHBOARD.QUERIES.SEARCH_PLACE_HOLDER}
              endAdornment={
                <InputAdornment position="end" className="searchInputAdornment">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    className="searchIcon"
                    onClick={handleSearch}
                  >
                    <img src={Search} alt="search" />
                  </IconButton>
                  {searchApplied && (
                    <HighlightOffIcon
                      onClick={() => {
                        setSearchApplied(false);
                        setSearch("");
                      }}
                      className="clearSerach"
                    />
                  )}
                </InputAdornment>
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: { sm: "none", xs: "none" },
            marginTop: { md: 0, sm: 20, xs: 20 },
            alignItems: "flex-start",
          }}
        />
      </Grid>
      <QueriesListing
        queries={queries}
        updatePagination={setPagination}
        pagination={pagination}
      />
    </Container>
  );
};

export default DesignerQueries;
