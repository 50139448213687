import React, { useRef, useEffect } from "react";

// Third Party Libraries
import { motion } from "framer-motion";

// Hooks
import { useMeasurePosition } from "~/hooks/useDraggable";

// Utils
import { SCROLL_THRESHOLD, SCROLL_SPEED } from "~/utils/constants";

// Icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DraggableList = ({
  item,
  updateOrder,
  updatePosition,
  index,
  containerRef,
}) => {
  const isDragged = useRef(false);
  const itemRef = useMeasurePosition((position) =>
    updatePosition(index, position)
  );

  const scrollInterval = useRef(null);

  const startAutoScroll = () => {
    if (scrollInterval.current || !containerRef.current) return;

    scrollInterval.current = setInterval(() => {
      if (!isDragged.current || !containerRef.current) {
        clearInterval(scrollInterval.current);
        scrollInterval.current = null;
        return;
      }

      const container = containerRef.current;
      const rect = container.getBoundingClientRect();
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const offsetY = itemRef.current?.getBoundingClientRect().top - rect.top;

      // Scroll up if dragging near the top
      if (offsetY < SCROLL_THRESHOLD && scrollTop > 0) {
        container.scrollTop -= SCROLL_SPEED;
      }
      // Scroll down if dragging near the bottom
      else if (
        offsetY > rect.height - SCROLL_THRESHOLD &&
        scrollTop < scrollHeight - rect.height
      ) {
        container.scrollTop += SCROLL_SPEED;
      }
    }, 16); // Runs every 16ms (~60fps)
  };

  const stopAutoScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }
  };

  useEffect(() => {
    if (isDragged.current) {
      startAutoScroll();
    } else {
      stopAutoScroll();
    }
    return stopAutoScroll;
  }, [isDragged.current]);

  return (
    <li ref={itemRef}>
      <motion.div
        dragConstraints={{ top: 0, bottom: 0 }}
        dragElastic={1}
        layout
        onDragStart={() => (isDragged.current = true, startAutoScroll())}
        onDragEnd={() => (isDragged.current = false)}
        animate={{ scale: isDragged.current ? 1 : 1 }}
        onViewportBoxUpdate={(_, delta) => {
          if (isDragged.current) updateOrder(index, delta.y.translate);
        }}
        drag="y"
        style={{ pointerEvents: "none" }} // Disable pointer events on the parent div
      >
        <div className="draggable-list-item">
          <span
            style={{ pointerEvents: "auto" }} // Enable pointer events on the child span
          >
            <DragIndicatorIcon sx={{ color: "#ed5c2f" }} />
          </span>
          {item?.title || item}
        </div>
      </motion.div>
    </li>
  );
};

export default DraggableList;
