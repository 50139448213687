import React, { useEffect, useMemo, useState } from "react";

//  Third party libraries
import Papa from "papaparse";
import io from "socket.io-client";

// Hooks
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import request from "~/utils/request";
import { MESSAGES } from "~/utils/message";
import { getUserToken } from "~/utils/auth";
import { dragDrop, SAMPLE_CSV } from "~/utils/constants";
import { getItem, setItem, removeItem } from "~/utils/local-storage";
import {
  downloadFile,
  isOfferGeneratorWebsite,
  numberWithComma,
} from "~/utils/helperFunctions";

// Components
import ContactsMapping from "./ContactsMapping";
import ProgressBar from "./ProgressBar";
import ActionModal from "./ActionModal";

// MUI Component
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Container, CircularProgress } from "@mui/material";

// Icons
import { FileAddCIcon, GreenCrossCIcon, GreenTickCIcon } from "~/assets/images";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

// styles
import "./styles.scss";

const ImportContacts = (props) => {
  const {
    reusable = false,
    setUploadProcess,
    setSelectedContact,
    selectedContact,
    cancel,
    switchTab,
    activeItemId,
    setImportedErrorRecordCount,
  } = props;
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [mappingModal, setMappingModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [contactsMapping, setContactsMapping] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [modalType, setModalType] = useState("success");
  const [requestId, setRequestId] = useState(null);
  const [processCsv, setProcessCsv] = useState(null);
  const [downloadProcessedResult, setDownloadProcessedResult] = useState(false);
  const [errors, setErrors] = useState({ status: false, message: "" });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const onDropAccepted = (files) => {
    if (requestId) {
      setErrors((prev) => ({
        ...prev,
        status: true,
        message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.REPLACE,
      }));
      return;
    } else if (errors.status) {
      setErrors((prev) => ({
        ...prev,
        status: false,
      }));
    }
    setSelectedFile(files);
    setFileName(files[0].name);
    setItem("fileName", files[0].name);
  };

  const onDropRejected = () => {
    setErrors((prev) => ({
      ...prev,
      status: true,
      message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.INVALID_FILE,
    }));
    return;
  };

  const onClose = async () => {
    await onFileRemove(false);
    navigate(`/contacts`);
  };

  const onCancel = async () => {
    setModalType("cancel");
    setActionModal(true);
  };

  const onFileRemove = async (notify = true) => {
    try {
      if (requestId) {
        const removeFile = await request.delete(
          `contacts/import/${requestId}/delete`
        );
        setSelectedFile([]);
        setFileName(null);
        setProcessCsv(null);
        setRequestId(null);
        reusable ? setUploadProcess(true) : undefined;
        removeLocalData();
        if (errors.status) {
          setErrors((prev) => ({
            ...prev,
            status: false,
          }));
        }
        notify ? dispatch(success(removeFile.data.message)) : null;
      } else {
        setSelectedFile([]);
        setFileName(null);
        removeLocalData();
      }
    } catch (error) {
      notify ? dispatch(failure(error?.response?.data?.message)) : null;
      return error;
    }
  };

  const exportCSV = async () => {
    try {
      setLoading(true);
      const exportCSV = await request.post("contacts/import", {
        reqId: requestId,
        errorRecordCount: processCsv.error,
      });
      setModalType("success");
      setActionModal(true);
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  const exportHeaders = async () => {
    if (selectedFile.length) {
      Papa.parse(selectedFile[0], {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: async function (results) {
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          const headerRow = rowsArray[0];
          const dataRows = results.data.slice(0, 2);
          if (!headerRow?.length || !dataRows?.length) {
            setErrors((prev) => ({
              ...prev,
              status: true,
              message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.EMPTY_FILE,
            }));
            return;
          }
          const headerOptions = [];
          headerRow.forEach((header) => {
            headerOptions.push({
              name: header,
              examples: dataRows.map((row) => row[header]),
            });
          });

          try {
            setLoading(true);
            const exportHeaders = await request.post(
              "contacts/import/getSuggestions",
              {
                headers: headerRow.toString(),
              }
            );
            const suggestion = [];
            suggestion.push(exportHeaders.data.data);
            const autoSuggestion = suggestion[0]["auto_suggested"];

            // Combine contact_fields and custom_fields
            const combinedFields = suggestion.map((item) => {
              return [
                ...item.contact_fields,
                ...item.custom_fields.map((field) => ({
                  label: field,
                  field: "",
                })),
              ];
            });

            const mappedFileds = combinedFields[0]
              .map((item) => {
                const matchingKey = Object.entries(autoSuggestion).find(
                  ([key, value]) => value === item.field
                );
                return {
                  label: item.label,
                  dbColumn: item.field || item.label,
                  value: matchingKey?.length ? matchingKey[0] : "",
                  required: item.required || false,
                  error: false,
                  display: item.isPropertyField
                    ? item.isPropertyField && isPropertyAddressEnabled
                    : true,
                };
              })
              .filter((item) => item.display !== false);
            setMappingOptions(headerOptions);
            setContactsMapping(mappedFileds);
            setMappingModal(true);
          } catch (error) {
            dispatch(failure(error.response.data.message));
            return error;
          } finally {
            setLoading(false);
          }
        },
      });
    } else {
      setErrors((prev) => ({
        ...prev,
        status: true,
        message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.REQUIRED,
      }));
    }
  };

  const downloadSampleCSV = () => {
    const blob = new Blob([SAMPLE_CSV], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBeforeUnload = () => {
    setItem("isReload", true);
  };

  const getLocalCsvData = () => ({
    isReload: getItem("isReload"),
    reqId: getItem("reqId"),
    file: getItem("fileName"),
    process: getItem("csvProcessing"),
  });

  const removeLocalData = () => {
    removeItem("isReload");
    removeItem("reqId");
    removeItem("fileName");
    removeItem("csvProcessing");
  };

  const restoreFromLocal = (reqId, file, process) => {
    setRequestId(reqId);
    setFileName(file);
    setProcessCsv(JSON.parse(process));
  };

  const handleDownloadProcessedResult = async () => {
    setDownloadProcessedResult(true);
    await downloadFile(processCsv.resultsCsvFile, "processed-result.csv");
    setDownloadProcessedResult(false);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "text/csv": [".csv"] },
      onDropAccepted,
      onDropRejected,
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...dragDrop.baseStyle,
      ...(isFocused ? dragDrop.focusedStyle : {}),
      ...(isDragAccept ? dragDrop.acceptStyle : {}),
      ...(isDragReject ? dragDrop.rejectStyle : {}),
      ...(errors.status ? dragDrop.rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, errors]
  );

  useEffect(() => {
    if (reusable && processCsv) {
      !processCsv.processing ? setUploadProcess(false) : undefined;
    }
  }, [processCsv]);

  useEffect(() => {
    // Connect to your Socket.IO server
    const socketConnection = io(import.meta.env.VITE_APP_BASE_URL, {
      query: {
        token: getUserToken(),
      },
    });

    if (requestId) {
      socketConnection.on(`import-contacts-progress-${requestId}`, (data) => {
        setProcessCsv((prev) => ({
          ...prev,
          ...data,
        }));
        setItem("csvProcessing", JSON.stringify(data));
        reusable && data?.error
          ? setImportedErrorRecordCount(data?.error)
          : undefined;
      });
    }
    reusable ? setSelectedContact(requestId) : undefined;
    // Clean up the socket connection when the component unmounts
    return () => {
      socketConnection.disconnect();
    };
  }, [requestId]);

  useEffect(() => {
    const { isReload, reqId, file, process } = getLocalCsvData();

    if (
      (isReload || (reusable && typeof selectedContact !== "object")) &&
      reqId &&
      file &&
      process
    ) {
      restoreFromLocal(reqId, file, process);
      setTimeout(() => {
        removeItem("isReload");
      }, 1000);
    } else {
      removeLocalData();
    }

    reusable ? setUploadProcess(true) : undefined;

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      reusable ? setUploadProcess(false) : undefined;
    };
  }, []);

  return (
    <div>
      <Container
        maxWidth="xxl"
        sx={{
          paddingLeft: reusable ? "0px !important" : "65px !important",
          marginBottom: reusable ? "100px !important" : "",
        }}
      >
        <Box className="importContactsWrapper">
          {!reusable && (
            <Typography>{MESSAGES.CONTACTS.IMPORT.TITLE}</Typography>
          )}
          <Box className="importFileWrapper">
            <Box className="fileBox" {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <img src={FileAddCIcon} alt="add" />
              <Typography>
                <span className="upload">
                  {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.TITLE}
                </span>
                {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.DRAG_TEXT}
                <br />
                <span className="files">
                  {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.FILE_TYPE}
                </span>
              </Typography>
            </Box>

            {errors.status && (
              <Typography className="errors">
                <span>{errors.message}</span>
              </Typography>
            )}

            <Box className="info">
              {processCsv && (
                <Box sx={{ width: "100%" }}>
                  <ProgressBar value={processCsv.current} />
                </Box>
              )}
              {!processCsv && !processCsv?.processing && (
                <Typography className="sample">
                  <span onClick={downloadSampleCSV}>
                    {MESSAGES.CONTACTS.IMPORT.DOWNLOAD}
                  </span>
                  {MESSAGES.CONTACTS.IMPORT.SAMPLE_FILE}
                </Typography>
              )}
              {fileName && (
                <Box className="addedFileName">
                  <img src={GreenTickCIcon} alt="green" />
                  {fileName.length >= 50 ? (
                    <Typography>
                      {fileName.substring(0, 30) + "...csv"}
                    </Typography>
                  ) : (
                    <Typography>{fileName}</Typography>
                  )}
                  {!processCsv?.processing && (
                    <img
                      src={GreenCrossCIcon}
                      alt="cross"
                      onClick={onFileRemove}
                    />
                  )}
                </Box>
              )}
              {processCsv && !processCsv.processing && (
                <Box>
                  <Typography>
                    {numberWithComma(processCsv.imported)}
                    {processCsv.imported <= 1
                      ? MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                          .VALID_CONTACT
                      : MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                          .VALID_CONTACTS}
                  </Typography>
                  <Box className="mappingError">
                    {(processCsv.error > 0 || processCsv.duplicate > 0) && (
                      <Typography>
                        {
                          MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .PROCESSED_RESULT_EMAIL_TEXT
                        }
                      </Typography>
                    )}
                    <Typography>
                      {MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS.REQUIRED}
                      {numberWithComma(processCsv.error)}
                      {processCsv.error <= 1
                        ? MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .MISSING_CONTACT
                        : MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .MISSING_CONTACTS}
                    </Typography>
                    <Typography>
                      {MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS.REQUIRED}
                      {numberWithComma(processCsv.duplicate)}
                      {processCsv.duplicate <= 1
                        ? MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .DUPLICATES_CONTACT
                        : MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .DUPLICATES_CONTACTS}
                    </Typography>
                  </Box>
                  {(processCsv.error > 0 || processCsv.duplicate > 0) && (
                    <Box className="addedFileName" sx={{ marginTop: "10px" }}>
                      <img src={GreenTickCIcon} alt="green" />
                      <Typography>
                        {
                          MESSAGES.CONTACTS.IMPORT.SMARTY_VALIDATIONS
                            .PROCESSED_RESULT
                        }
                      </Typography>
                      {!processCsv?.processing &&
                        (downloadProcessedResult ? (
                          <CircularProgress
                            sx={{
                              color: "#03a155",
                              width: "20px !important",
                              height: "20px !important",
                            }}
                          />
                        ) : (
                          <SaveAltIcon
                            sx={{ color: "#03a155", cursor: "pointer" }}
                            onClick={handleDownloadProcessedResult}
                          />
                        ))}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {!reusable && (
          <Box className="importContactBtns">
            <Button onClick={onCancel}>
              {MESSAGES.CONTACTS.IMPORT.CANCEL_BUTTON}
            </Button>
            <Button
              disabled={
                (processCsv && processCsv.processing) ||
                (processCsv && processCsv.imported <= 0)
              }
              onClick={processCsv ? exportCSV : exportHeaders}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.CONTACTS.IMPORT.SUBMIT_BUTTON
              )}
            </Button>
          </Box>
        )}
      </Container>
      {reusable ? (
        <Box className="orderFooterWrapper">
          <Button
            className="backBtn"
            disabled={activeItemId == 1}
            onClick={() =>
              activeItemId > 1 ? switchTab(activeItemId - 1) : undefined
            }
          >
            {MESSAGES.ORDERS.CREATE.BACK_BUTTON}
          </Button>
          <Box className="btnOuter">
            <Button className="cancelBtn" onClick={cancel}>
              {MESSAGES.ORDERS.CREATE.CANCEL_BUTTON}
            </Button>
            <Button
              className="nextBtn"
              disabled={
                (processCsv && processCsv.processing) ||
                (processCsv && processCsv.imported <= 0)
              }
              onClick={processCsv ? exportCSV : exportHeaders}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {/* Contacts Mapping Modal */}
      <ContactsMapping
        open={mappingModal}
        handleClose={() => {
          setMappingModal(false);
        }}
        mappingFileds={contactsMapping}
        options={mappingOptions}
        updateMapping={setContactsMapping}
        selectedFile={selectedFile}
        setRequestId={setRequestId}
        setProcessCsv={setProcessCsv}
      />
      {/* Actions Modals */}
      {!reusable && (
        <ActionModal
          open={actionModal}
          exportedContacts={processCsv ? processCsv.imported : 0}
          handleClose={() => setActionModal(false)}
          modalType={modalType}
          onSubmit={onClose}
        />
      )}
    </div>
  );
};

export default ImportContacts;
