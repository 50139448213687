import { dateFormat } from './date-format';

export const convertContactData = (contact) => {
    const convertedData = [
        {
            id: 1,
            attribute: 'First Name',
            value: `${contact.firstName}` || ' -',
        },
        {
            id: 2,
            attribute: 'Last Name',
            value: `${contact.lastName}`  || ' -',
        },
        { id: 3, attribute: 'Company', value: contact.companyName || ' -' },
        { id: 4, attribute: 'Mailing Address 1', value: contact.address1 || ' -' },
        { id: 5, attribute: 'Mailing Address 2', value: contact.address2 || ' -' },
        { id: 6, attribute: 'Mailing City', value: contact.city || ' -' },
        { id: 7, attribute: 'Mailing State', value: contact.state || ' -' },
        { id: 8, attribute: 'Mailing Zip Code', value: contact.zip || ' -' },
        { id: 9, attribute: 'Contact Phone Number', value: contact.phoneNo || ' -' },
        {
            id: 10, attribute: 'Tags', value: contact.ContactLabels.length > 0 && (contact.ContactLabels.map(label => label.title))
        },
        { id: 11, attribute: 'Contact Email', value: contact.email || ' -' },

        { id: 12, attribute: 'Address Status', value: contact.addressStatus || ' -' },
        { id: 13, attribute: 'Total Mailed', value: contact.totalMailed || ' -' },
        { id: 14, attribute: 'Last Mailed Status', value: contact.lastMailedStatus || ' -' },
        { id: 15, attribute: 'Last Mailed Date', value: dateFormat(contact.lastMailedDate) || ' -' },
        {
            id: 16,
            attribute: 'Date Created',
            value: dateFormat(contact.createdAt) || ' -',
        },
        {
            id: 17,
            attribute: 'Use as Return Address',
            value: contact.isReturnAddress ? 'Yes' : 'No',
        },
        {
            id: 18,
            attribute: 'Last QR Code Scan',
            value: contact.lastQrScan ? dateFormat(contact?.lastQrScan?.scannedAt) : ' -',
        }
    ];

    return convertedData;
};
