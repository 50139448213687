//Actions
import {
    SET_CONTACT_IMPORT_LOADER, SET_CONTACT_IMPORTS_DATA, LOGOUT,
} from "../actions/action-types"

const initialState = {
    contactImports: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalContactsInDB: null,
    loading: false,
    labels: [],
    applyFilters: false,
    filterSize: null,
    filters: {},
    returnAddresses: [],
};

const contactImportsReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CONTACT_IMPORTS_DATA:
            return {
                ...state, contactImports: payload.rows, rowsCount: payload.count, totalContactsInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_CONTACT_IMPORT_LOADER:
            return { ...state, loading: payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { contactImportsReducers };
