export const parseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {}
};

export const formatNumberWithMaxDecimals = (number, decimalPoints = 3) => {
  return number.toString().split(".")[1] || "".length > decimalPoints
    ? "$" + parseFloat(number).toFixed(decimalPoints)
    : number === " -"
    ? number
    : "$" + number;
};

export const balanceFormatter = (
  balance,
  decimalPoints = 2,
  compactNotation = false
) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPoints,
    notation: compactNotation ? "compact" : "standard",
  });
  if (balance) {
    const result = formatter.format(balance);
    return result;
  } else {
    return "$0.00";
  }
};

export const numberWithComma = (x) => {
  if (x === "" || x === undefined) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isSlackWebhook = (url) => {
  return url.includes("hooks.slack.com");
};

export const percentager = (value, total) => {
  if (total === 0) {
    return 0;
  }
  return ((value / total) * 100).toFixed(1);
};

export const isNumberString = (str) => {
  return /^\d+$/.test(str);
};

export const transformData = (obj) => {
  Object.keys(obj).forEach((key) => {
    const data = JSON.parse(obj[key]);
    obj[key] = data;
  });
  return obj;
};

export const stringLength = (str, len = 30) => {
  if (str?.length > len) {
    return true;
  } else {
    return false;
  }
};

export const getFullName = (first, last) => {
  const firstName = first?.trim() || '';
  const lastName = last?.trim() || '';
  const fullName = firstName || lastName ? `${firstName} ${lastName}`.trim() : ' -';
  return fullName
};

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {})
    .catch((err) => console.error("Failed to copy text: ", err));
};

export const trimName = (name) => {
  const words = name.trim().split(/\s+/);
  return words.length > 1 ? words.join(" ") : words[0];
};

export const getParsedObject = (data) => {
  try {
    return typeof data === "string" ? JSON.parse(data) : data;
  } catch (error) {
    return error;
  }
};

export const getEvent = (data) => {
  if (data?.event) {
    return data?.event;
  }

  const requestBody = getParsedObject(data?.value?.request?.body);

  if (isSlackWebhook(data?.value?.request?.url)) {
    const slackText = getParsedObject(requestBody?.text);
    return slackText?.event || " -";
  }

  return requestBody?.event || " -";
};

export const trimSpaces = (str) => {
  return str.replace(/\s+/g, " ").trim();
};

export const removeSThroughOne = (message) => {
  // Replace '1 <word>s' with '1 <word>' for any word followed by 's'
  let result = message.replace(/\b1 (\w+)s\b/g, "1 $1");

  return result;
};

export const downloadFile = async (fileUrl, fileName) => {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("There was a problem with the download:", error);
  }
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol (optional)
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const isOfferGeneratorWebsite = () => {
  return window?.location?.hostname?.includes("offer-generator");
};
