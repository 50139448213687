import React, { useState, useEffect } from "react";

// Hooks
import { useSelector } from "react-redux";

// Utils
import { MESSAGES } from "~/utils/message";
import { dateFormat } from "~/utils/date-format";
import { getEvent } from "~/utils/helperFunctions";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Tooltip, Stack, Pagination, PaginationItem } from "@mui/material";

// Components
import Loader from "~/components/General/Loader";

// Icon
import { ArrowRightCIcon, NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";

const WebhookLogTable = (props) => {
  const { rows, setSelectedWebhookLog, setDisplayLogPage, pagination, setPagination, loading } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleRowClick = (params, event) => {
    setSelectedWebhookLog(params.row);
    setDisplayLogPage(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Event",
      width: 300,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 2 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip title={getEvent(params.row)}>
            <Typography>{getEvent(params.row)}</Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "key",
      headerName: "Status Code",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 1 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip
            title={
              params.row?.responseStatusCode ||
              params.row?.value?.response?.status ||
              " -"
            }
          >
            <Typography>
              {params.row?.responseStatusCode ||
                params.row?.value?.response?.status ||
                " -"}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 1 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip title={dateFormat(params.row?.createdAt) || " -"}>
            <Typography>
              {dateFormat(params.row?.createdAt) || " -"}
              <img className="arrow-right" src={ArrowRightCIcon} alt="icon" />
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleChangePage = (event, page) => {
    setPagination(
      {
        page: page,
        pageSize: pagination.pageSize,
      }
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        margin: "20px 0px 100px",
      }}
      className="webhookTableWrapper webhookLog"
    >
      <Typography className="title">
        {MESSAGES.SETTINGS.WEBHOOKS.LOGS.TITLE}
      </Typography>

      <DataGrid
        rows={rows?.rows || []}
        rowCount={rows?.total || 0}
        columns={columns}
        paginationMode="server"
        hideFooterSelectedRowCount
        loading={loading}
        hideFooterPagination
        rowSelection={false}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="weblogTableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="webhookLogPagination">
        <Pagination
          count={rows?.lastPage || 1}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={pagination?.currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Typography>
          Showing {rows?.total ? rows?.from : 0} to {rows?.total ? rows?.to : 0} of {rows?.total} results
        </Typography>
      </Box>
    </div>
  );
};

export default WebhookLogTable;
