import React, { useEffect, useState } from "react";

// Hooks
import { useSelector } from "react-redux";

// libraries
import { Outlet } from "react-router-dom";

// Components
import Header from "./Header";
import SideBar from "./SideBar";

// Utils
import { getImpersonationMode } from "~/utils/auth";

// Mui Components
import { Grid, IconButton, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { isOfferGeneratorWebsite } from "~/utils/helperFunctions";

const Layout = (props) => {
  const {
    isSideBar,
    name,
    isAdminMode,
    onClick,
    isWidgetVisible,
    setIsWidgetVisible,
    hideHeader = false,
  } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const isImpersonatedMode = getImpersonationMode() || false;
  const currentMode = useSelector((state) =>
    state.userReducers.user.isLiveMode === true
      ? "Live"
      : state.userReducers.user.isLiveMode === false
      ? "Test"
      : null
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!hideHeader && (
        <Header
          isAdminMode={isAdminMode}
          onClick={onClick}
          isWidgetVisible={isWidgetVisible}
          setIsWidgetVisible={setIsWidgetVisible}
        />
      )}
      <div
        className={
          isOfferGeneratorWebsite()
            ? ``
            : isImpersonatedMode && currentMode === "Test"
            ? "dashboard-body modeReminder"
            : isImpersonatedMode
            ? "dashboard-body impersonate"
            : "dashboard-body"
        }
        id="dashboard"
      >
        {isSideBar && isSidebarOpen ? (
          <>
            <div
              className="overlay"
              onClick={() => {
                setIsSidebarOpen(false);
              }}
            />
            <Grid container spacing={0}>
              <Grid
                item
                xxl={isSidebarOpen ? 1 : 0}
                xl={isSidebarOpen ? 1 : 0}
                lg={isSidebarOpen ? 1 : 0}
                md={1}
                sm={1}
              >
                <SideBar name={name} isImpersonatedMode={isImpersonatedMode} />
              </Grid>
              <Grid
                className="userProfileGrid"
                item
                xxl={11}
                xl={10}
                lg={11}
                md={11}
                sm={11}
              >
                <Container maxWidth="xxl">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setIsSidebarOpen((prev) => !prev)}
                    className="hamburgerIconSidebar"
                  >
                    <MenuIcon sx={{ color: "#000000" }} />
                  </IconButton>
                  <Outlet />
                </Container>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {isSideBar && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setIsSidebarOpen((prev) => !prev)}
                className="hamburgerIconSidebar"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Outlet />
          </>
        )}
      </div>
    </>
  );
};

export default Layout;
