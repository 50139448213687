import React, { useState } from "react";

// Third Party Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { rotateApiKey } from "~/redux/actions/apiKeys-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { dateProtector } from "~/utils/date-format";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RotateApiModal = (props) => {
  const { selectedApi, openModal, handleCloseModal } = props;
  const [startDate, setStartDate] = useState(null);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => {
    handleCloseModal();
    setStartDate(null);
  };

  const rotateApi = async () => {
    try {
      const payload = {
        expiredAt: startDate?.toISOString().slice(0, 10),
        isSecretRequired: false,
        selectedApi,
      };
      setLoading(true);
      const response = await dispatch(rotateApiKey(payload));
      dispatch(success(response.data.message));
      onClose();
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="rotateApiModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.API_KEYS.ROTATE.TITLE}
            </Typography>
          </Box>

          <Box className="mailedDate editNameInputsWrapper">
            <Box className="selectDate">
              <InputLabel className="dateLabel">
                {MESSAGES.SETTINGS.API_KEYS.ROTATE.DATE_LABEL}
              </InputLabel>
              <KeyboardArrowDownIcon
                sx={{
                  color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                  cursor: "pointer",
                  position: "absolute",
                  top: "40.5px",
                  right: "7px",
                  zIndex: "1",
                }}
              />
              <DatePicker
                selected={startDate}
                minDate={addDays(new Date(), 1)}
                onChange={(update) => {
                  dateProtector(update);
                  setStartDate(update);
                }}
                onFocus={() => setIsDatepickerOpen(true)}
                onBlur={() => setIsDatepickerOpen(false)}
                placeholderText={
                  MESSAGES.SETTINGS.API_KEYS.ROTATE.DATE_PLACEHOLDER
                }
              />
            </Box>
          </Box>
          <Box className="editNameModalBtns">
            <Button onClick={onClose} className="btnCancel">
              {MESSAGES.SETTINGS.API_KEYS.CANCEL_BUTTON}
            </Button>
            <Button className="btnSave" type="submit" onClick={rotateApi} disabled={loading}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.SETTINGS.API_KEYS.SUBMIT_BUTTON
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default RotateApiModal;
