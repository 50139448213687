import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import "./JsonRenderer.scss";

const JsonRenderer = ({ json, title }) => {
  return (
    <div className="json-renderer">
      <h3 className="json-renderer__title">{title}</h3>
      <div className="json-renderer__content">
        <SyntaxHighlighter language="json">
          {JSON.stringify(json, null, 2)}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default JsonRenderer;
