import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import {
  getTeamMembersData,
  deleteMember,
} from "~/redux/actions/user-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { DeleteCDCIcon, CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = (props) => {
  const { selectedUser, openModal, handleCloseModal } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteUser = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteMember(selectedUser));
      if (response.status === 200) {
        dispatch(success(response?.data?.message));
        dispatch(getTeamMembersData());
        handleCloseModal();
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      return error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
   
  };

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="userDeleteModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        className="crossIcon"
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={CrossModalCIcon} alt="crossIcon" />
      </IconButton>
      <DialogContent dividers className="deleteModalContent">
        <img src={DeleteCDCIcon} alt="delete" />
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ACCOUNT.USERS.DELETE_USER.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.ACCOUNT.USERS.DELETE_USER.HEADING}
          </Typography>
        </Box>
        <Box className="deleteModalBtns">
          <Button onClick={handleCloseModal}>
            {MESSAGES.ACCOUNT.USERS.DELETE_USER.CANCEL_BUTTON}
          </Button>
          <Button onClick={deleteUser} disabled={loading}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.ACCOUNT.USERS.DELETE_USER.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeleteModal;
