import React from "react";
import "./Wrong.scss";

function Wrong() {
  return (
    <div className="error-container">
      <h1>Something Went Wrong</h1>
      <p>Please try again later.</p>
    </div>
  );
}

export default Wrong;
