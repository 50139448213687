import React, { useEffect, useState } from "react";

// Hooks
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useScreenWidth from "~/hooks/useScreenWidth";
import { displayTooltip } from "~/hooks/displayTooltip";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";

//Utils
import { DEFAULT_PAGE_SIZES, DEFAULT_PAGINATION } from "~/utils/constants";
import { createViewProofForOrder } from "~/utils/template-builder";
import { MESSAGES } from "~/utils/message";
import { stringLength } from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  CircularProgress,
  Stack,
  IconButton,
  Menu,
  Checkbox,
} from "@mui/material";

// Icons
import { DisableCIcon, DispatchCIcon, NoContactCIcon } from "~/assets/images";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";

// Styles
import "./styles.scss";

const productTypeStyles = {
  overflow: "auto !important",
  maxWidth: "130px",
  "@media (width < 1380px)": {
    maxWidth: "100%",
  },
};

const OrdersTable = ({
  pagination,
  updatePagination,
  setOpenActionModal,
  setDispatchModal,
  setSelectedOrder,
  searchApplied,
  setSelectedRows,
  selectedRows,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [downloadingProof, setDownloadingProof] = useState({
    status: false,
    rowId: null,
  });
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowId, setActiveRowId] = useState(null);

  const open = Boolean(anchorEl);

  const pageSizes = DEFAULT_PAGE_SIZES;

  const fetchedRows = useSelector((state) => state.adminOrdersReducers.orders);

  const rowsCount = useSelector((state) => state.adminOrdersReducers.rowsCount);
  const to = useSelector((state) => state.adminOrdersReducers.to);
  const from = useSelector((state) => state.adminOrdersReducers.from);
  const lastPage = useSelector(
    (state) => state.adminOrdersReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminOrdersReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminOrdersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector((state) => state.adminOrdersReducers.loading);
  const dispatchLoadingOrderId = useSelector(
    (state) => state.adminOrdersReducers.dispatchLoadingOrderId
  );

  const dispatch = useDispatch();

  const downloadViewProof = async (params) => {
    try {
      setDownloadingProof({
        status: true,
        id: params.row.orderId,
      });

      await dispatch(
        createViewProofForOrder(
          `Order-${params.row.orderId}`,
          {
            orderId: params.row.orderId,
          },
          "admin-order"
        )
      );
      setDownloadingProof({
        status: false,
        id: null,
      });
    } catch (error) {
      setDownloadingProof({
        status: false,
        id: null,
      });
      dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
    }
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((order) => ({ ...order, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((order) => ({ ...order, checked: true }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((order) => order.orderId);
    setSelectedRows(selectedRowIds);
  };

  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((order) => ({ ...order, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();
    // Update the selectedRow object directly in the rows array
    const updatedRows = rows.map((row) =>
      row.orderId === selectedRow.orderId ? { ...row, checked: !row.checked } : row
    );

    // If the user selects all orders and then removes a single order, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.orderId === selectedRow.orderId
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );

      const filteredRowsIds = filteredRows
        .map((order) => order.checked === true && order.orderId)
        .filter(Boolean);

      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.orderId)
      ? selectedRows.filter((id) => id !== selectedRow.orderId) // Remove the ID if already selected
      : [...selectedRows, selectedRow.orderId]; // Add the ID if not selected

    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);

    setRemoveBulkSelect(true);
  };

  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((order) => ({
      ...order,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((order) => order.orderId);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };

  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((order) => ({
      ...order,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [currentPage, perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
          >
            <MenuItem
              onClick={rowsCount > 0 ? selectAll : undefined}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_ALL_ITEMS} ({rowsCount})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "id",
      headerName: "Order ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.orderId || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        params.row?.createdBy ? (
          <Tooltip title={`ID: ${params.row.userId}`}>
            <Box className="addressWrapper">
              <Link className="link" to={`/admin/users/${params.row.userId}`}>
                <Typography>{params.row.createdBy || " -"}</Typography>
              </Link>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{" -"}</Typography>
          </Box>
        ),
    },
    {
      field: "batchId",
      headerName: "Batch ID",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        params.row.batchId ? (
          <Box className="addressWrapper">
            <Link className="link" to={`/admin/batches/${params.row.batchId}`}>
              <Typography>{params.row.batchId || " -"}</Typography>
            </Link>
          </Box>
        ) : (
          <Box className="addressWrapper">
            <Typography>{" -"}</Typography>
          </Box>
        ),
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.source || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Product Type",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(params.row.productType, 15) ? (
          <Tooltip title={params.row.productType}>
            <Box className="addressWrapper" sx={productTypeStyles}>
              <Typography>{params.row.productType || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper" sx={{ overflow: "auto !important" }}>
            <Typography>{params.row.productType || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valueWrapper">
          <Typography>{params.row.deliverableQuantity || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.revenue || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.scheduleDate || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "mailingDate",
      headerName: "Mailing Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Tooltip title={params.row.mailingDate}>
          <Box className="addressWrapper">
            <Typography>{params.row.mailingDate || " -"}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.status || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.paymentStatus || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="actionsWrapper">
          {params.row.status !== "Canceled" && (
            <>
              <Tooltip title="Download Proof">
                {downloadingProof.status &&
                downloadingProof.id === params.row.orderId ? (
                  <CircularProgress
                    sx={{
                      color: "#ED5C2F",
                      width: "20px !important",
                      height: "20px !important",
                    }}
                  />
                ) : (
                  <SaveAltIcon
                    sx={{
                      fill: "#545454",
                      stroke: "#ffffff",
                      strokeWidth: "0.6px",
                    }}
                    onClick={() => downloadViewProof(params)}
                  />
                )}
              </Tooltip>
              {params.row.paymentStatus !== "Paid" && (
                <Tooltip title="Cancel Order">
                  <img
                    src={DisableCIcon}
                    alt="Cancel"
                    onClick={() => {
                      setSelectedOrder(params.row.orderId),
                        setOpenActionModal(true);
                    }}
                  />
                </Tooltip>
              )}
              {dispatchLoadingOrderId === params.row.orderId ? (
                <CircularProgress
                  sx={{
                    color: "#ED5C2F",
                    width: "20px!important",
                    height: "20px!important",
                  }}
                />
              ) : params.row.paymentStatus === "Pending" &&
                params.row.status === "Scheduled" ? (
                <Tooltip
                  title={
                    params.row.dispatchedAt === null
                      ? "Dispatch Now"
                      : "Dispatched"
                  }
                >
                  <img
                    height={14}
                    src={DispatchCIcon}
                    alt="icon"
                    onClick={
                      params.row.dispatchedAt === null
                        ? () => {
                            setSelectedOrder(params.row.orderId),
                              setDispatchModal(true);
                          }
                        : null
                    }
                    style={{
                      opacity: params.row.dispatchedAt === null ? "1" : "0.5",
                      cursor:
                        params.row.dispatchedAt === null
                          ? "pointer"
                          : "not-allowed",
                    }}
                  />
                </Tooltip>
              ) : params.row.status === "Processing" ||
                (params.row.status === "Scheduled" &&
                  params.row.paymentStatus === "Paid") ? (
                <Tooltip title="Dispatched">
                  <img
                    height={14}
                    src={DispatchCIcon}
                    alt="icon"
                    style={{
                      opacity: "0.5",
                      cursor: "not-allowed",
                    }}
                  />
                </Tooltip>
              ) : null}
            </>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="adminUserTable">
        <div
          style={{
            marginTop: "14px",
            width: "100%",
          }}
          className="adminOrderstableWrapper"
        >
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={rowsCount}
            pagination={pagination}
            paginationMode="server"
            hideFooterSelectedRowCount
            hideFooterPagination
            rowSelection={false}
            getRowId={(row) => row.key}
            disableColumnMenu={true}
            className="adminOrdersTable"
            slots={{
              noRowsOverlay: () => (
                <Stack className="noRowsTextContacts">
                  <img src={NoContactCIcon} alt="NoContact" />
                  {`${MESSAGES.NO_RESULTS_TEXT} ${
                    !searchApplied ? "filters" : "search"
                  }`}
                </Stack>
              ),
              noResultsOverlay: () => (
                <Stack className="noRowsTextContacts">
                  <img src={NoContactCIcon} alt="NoContact" />
                  {MESSAGES.NO_ROWS_TEXT}
                </Stack>
              ),
              loadingOverlay: Loader,
            }}
          />
          <Box className="paginationWrapper">
            <Pagination
              count={lastPage}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              page={currentPage}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIosNewIcon,
                    next: ArrowForwardIosIcon,
                  }}
                  {...item}
                />
              )}
            />
            <Select
              className={
                perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
              }
              value={perPage}
            >
              {pageSizes.map((pageSize) => {
                return (
                  <MenuItem
                    key={pageSize}
                    value={pageSize}
                    onClick={() => {
                      updatePagination({
                        page: 0,
                        pageSize: pageSize,
                      });
                    }}
                  >
                    {pageSize}
                  </MenuItem>
                );
              })}
            </Select>

            <Typography>
              Showing {rowsCount ? from : 0} to{" "}
              {rowsCount < to ? rowsCount : to} of {rowsCount} results
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
};

export default OrdersTable;
