import React, { useState, useEffect, useRef } from "react";

//moment
import moment from "moment";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import {
  fetchOrders,
  fetchFiltersLabels,
  orderBulkActions,
} from "~/redux/actions/orders-actions";
import { CLEAR_ORDERS_FILTERS } from "~/redux/actions/action-types";
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { dateFormat } from "~/utils/date-format";

// Component
import Table from "~/components/Orders/OrderListing";
import AdvanceFilters from "./AdvanceFilters";
import NoOrdersScreen from "./NoOrderScreen";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";

// MUI Components
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import {
  ActiveFilterCIcon,
  InActiveFilterCIcon,
  ContactSearchCIcon,
  TemplateAddCIcon,
  ProcessOrderCIcon,
  CancelOrderCIcon,
  ExportCIcon,
  CreateOrderCIcon,
  WarningCIcon,
} from "~/assets/images";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

// styles
import "./styles.scss";

const Orders = () => {
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";
  const [openCreateConfirm, setOpenCreateConfirm] = useState(false);
  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const handleCreateOrder = () => {
    if(currentMode===true){
      navigate("/orders/create-order");
    }else{
      setOpenCreateConfirm(true);
    }
    
  };
  
  const confirmCreateOrder = () => {
    setOpenCreateConfirm(false); 
    navigate("/orders/create-order");
   
  };
  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [action, setAction] = useState({
    message: "",
    currentAction: null,
    successButtonName: "",
  });

  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalOrdersInDB = useSelector(
    (state) => state.ordersReducers.totalOrdersInDB
  );

  const applyFilters = useSelector(
    (state) => state.ordersReducers.applyFilters
  );
  const filters = useSelector((state) => state.ordersReducers.filters);
  const filterSize = useSelector((state) => state.ordersReducers.filterSize);

  const handleSearch = () => {
    if (search.trimStart()) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getOrders();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getOrders = () => {
    let payload = {
      pagination,
      search: search.length ? search.trimStart() : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchOrders(payload));
  };

  const cancelOrders = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "cancel",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await orderBulkActions(payload);
      getOrders();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setLoading(false);
      setActionModal(false);
    }
  };

  const payAndProcessOrders = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "pay and process",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await orderBulkActions(payload);
      getOrders();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.message));
    } finally {
      setLoading(false);
      setActionModal(false);
    }
  };

  const exportOrders = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "export",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await orderBulkActions(payload);
      getOrders();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.message));
    } finally {
      setLoading(false);
      setActionModal(false);
    }
  };

  selectedRows[0] === -1 || selectedRows.length > 1
    ? MESSAGES.CONTACTS.MULTI_DELETE_HEADER
    : MESSAGES.CONTACTS.SINGLE_DELETE_HEADER;

  const handleActions = async (type) => {
    if (type === "cancel") {
      setAction({
        message:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MULTI_MESSAGE
            : MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MESSAGE,
        currentAction: "cancel",
        successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUCCESS_BUTTON,
        subMessage:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MULTI_SUB_MESSAGE
            : MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUB_MESSAGE,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.HEADING,
        icon: CancelOrderCIcon,
      });
    } else if (type === "payAndProcess") {
      const currentDate = moment();
      setAction({
        message: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MESSAGE,
        currentAction: "payAndProcess",
        successButtonName:
          MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUCCESS_BUTTON,
        subMessage:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? `${
                MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MULTI_SUB_MESSAGE
              } ${dateFormat(currentDate, true)}`
            : `${
                MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUB_MESSAGE
              } ${dateFormat(currentDate, true)}`,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.HEADING,
        icon: ProcessOrderCIcon,
      });
    } else if (type === "export") {
      setAction({
        message:
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ORDERS.DETAIL.MODALS.EXPORT.MULTI_MESSAGE
            : MESSAGES.ORDERS.DETAIL.MODALS.EXPORT.MESSAGE,
        currentAction: "export",
        successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.EXPORT.SUCCESS_BUTTON,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.EXPORT.HEADING,
        isExport: true,
      });
    }
    setActionModal(true);
  };

  useEffect(() => {
    setIsMounted(true);
    getOrders();
    dispatch(fetchFiltersLabels());
    return () => {
      dispatch({ type: CLEAR_ORDERS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getOrders();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getOrders();
    }
  }, [search]);

  useEffect(() => {
    if (isMounted) {
      let payload = {
        DEFAULT_PAGINATION,
        search: search.length ? search : null,
      };
      dispatch(fetchOrders(payload));
    }

    if(openCreateConfirm ===true && currentMode===true){
      setOpenCreateConfirm(false); 
      navigate("/orders/create-order");
    }
  }, [currentMode]);

  const noOrdersInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoOrdersScreen 
        handleCreateOrder={handleCreateOrder} 
        openCreateConfirm={openCreateConfirm} setOpenCreateConfirm={setOpenCreateConfirm} confirmCreateOrder={confirmCreateOrder}/>
      </Container>
    );
  };

  if (totalOrdersInDB < 1 && totalOrdersInDB !== null) {
    return noOrdersInDb();
  }

  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Grid container>
        <Grid
          item
          lg={6}
          md={selectedRows.length > 0 ? 12 : 6}
          sm={12}
          xs={12}
          className="searchBtnWrapper"
        >
          <Box className="searchWrapper">
            <Input
              className={`searchInput ${searchApplied ? "searchApplied" : ""}`}
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.trimStart());
              }}
              onKeyDown={searchKeyDown}
              placeholder={MESSAGES.ORDERS.SEARCH_PLACE_HOLDER}
              endAdornment={
                <InputAdornment position="end" className="searchInputAdornment">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    className="searchIcon"
                    onClick={handleSearch}
                  >
                    <img src={ContactSearchCIcon} alt="search" />
                  </IconButton>
                  {searchApplied && (
                    <HighlightOffIcon
                      onClick={() => {
                        setSearch("");
                      }}
                      className="clearSerach"
                    />
                  )}
                </InputAdornment>
              }
            />
            <Box className="filterBtnWrapper">
              <img
                onClick={() => {
                  setOpenAdvanceFilters(true);
                }}
                className={
                  filterSize > 0
                    ? "filterImage activeFilter"
                    : "filterImage noFilter"
                }
                src={filterSize > 0 ? ActiveFilterCIcon : InActiveFilterCIcon}
                alt="Filter"
              />
              {filterSize > 0 && (
                <Typography className="filtersCounter">{filterSize}</Typography>
              )}
            </Box>
            {filterSize > 0 && (
              <Button
                onClick={() => {
                  childRef.current.clearFilters();
                }}
                className="clearFilterBtn"
              >
                {MESSAGES.ORDERS.CLEAR_FILTER_BTN_TEXT}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={selectedRows.length > 0 ? 12 : 6}
          sm={12}
          xs={12}
          sx={{
            marginTop: { md: "0px", sm: "20px", xs: "20px" },
            alignItems: "flex-start",
          }}
        >
          {/* Create Button */}
          <Box
            className="actionsBtnWrapper"
            sx={{
              "@media (max-width:1199px)": {
                marginTop: selectedRows.length > 0 && "20px",
              },
            }}
          >
            {selectedRows.length > 0 && (
              <>
                <Button onClick={() => handleActions("export")}>
                  <img src={ExportCIcon} alt="actions" />
                  {MESSAGES.ORDERS.EXPORT_BTN_TEXT}
                </Button>
                <Button onClick={() => handleActions("cancel")}>
                  <CloseIcon />
                  {MESSAGES.ORDERS.CANCEL_ORDER_BTN_TEXT}
                </Button>
                <Button onClick={() => handleActions("payAndProcess")}>
                  <DoneIcon />
                  {MESSAGES.ORDERS.PAY_AND_PROCESS_BTN_TEXT}
                </Button>
              </>
            )}
            <Button
              className="templateCreateBtn"
              onClick={handleCreateOrder}
            >
              <img src={TemplateAddCIcon} alt="actions" />
              <Typography>Create</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Table
        pagination={pagination}
        updatePagination={setPagination}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        searchApplied={searchApplied}
      />
      <AdvanceFilters
        open={openAdvanceFilters}
        handleCloseFilter={() => {
          setOpenAdvanceFilters(false);
        }}
        ref={childRef}
        updatePagination={setPagination}
      />
      {actionModal && (
        <ConfirmDialog
          dialog={action}
          open={actionModal}
          loading={loading}
          handleClose={() => setActionModal(false)}
          handleOk={
            action.currentAction === "cancel"
              ? cancelOrders
              : action.currentAction === "payAndProcess"
              ? payAndProcessOrders
              : action.currentAction === "export"
              ? exportOrders
              : undefined
          }
        />
      )}

      {openCreateConfirm && (
        <ConfirmDialog
          dialog={{
            heading: "Create New Test Order",
            subMessage: "This order is being placed on Test Mode and will not mail. If you wish to  place an order on Live Mode, please switch to Live Mode.",
            successButtonName: "Continue anyway",
            icon: WarningCIcon,
            currentAction:"mode_modal"
          }}
          
          open={openCreateConfirm}
          handleClose={() => setOpenCreateConfirm(false)}
          handleOk={confirmCreateOrder}
        />
      )}

    </Container>
  );
};

export default Orders;
