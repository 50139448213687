import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { removeFundsToUserByAdmin } from "~/redux/actions/admin-users-actions.js";
import { failure, success } from "~/redux/actions/snackbar-actions.js";

// utils
import { MESSAGES } from "~/utils/message.js";
import { numberWithComma } from "~/utils/helperFunctions.js";
import { MIN_FUNDING_LIMIT } from "~/utils/constants";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Input, InputLabel, CircularProgress } from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RemoveFundsByAdmin = (props) => {
  const { handleClose, open, selectedUser } = props;

  const [fund, setFund] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [respError, setRespError] = useState("");
  const [activeTab, setActiveTab] = useState(null);

  const dispatch = useDispatch();

  const validateFunding = (value, setErrorCallback) => {
    if (isNaN(value) || value < 0) {
      setErrorCallback(MESSAGES.ACCOUNT.WALLET.STRIPE.REMOVE_FUNDING_VALIDATION);
      return false;
    } else {
      setErrorCallback("");
      return true;
    }
  };

  const handleRemoveFunding = async () => {
    const isFundValid = validateFunding(parseFloat(fund.toString().replaceAll(",", "")), setError);
    if (!isFundValid) return;
    try {
      const payload = {
        id: selectedUser?.id,
        amount: parseFloat(fund.toString().replaceAll(",", "")),
        reason: reason,
      };
      if (!payload.amount || payload.amount > 999999) {
        !payload.amount &&
          setError(MESSAGES.ACCOUNT.WALLET.STRIPE.PLEASE_ENTER_VALID_AMOUNT);
        payload.amount > 999999 &&
          setError(MESSAGES.ACCOUNT.WALLET.STRIPE.AMOUNT_VALIDATION_ERROR);
        return;
      } else {
        setError("");
      }
      if (payload.reason && payload.reason.length > 200) {
        setReasonError(
          MESSAGES.ACCOUNT.WALLET.STRIPE.REASON_VALIDATION_MESSAGE
        );
        return;
      } else {
        setReasonError("");
      }
      setLoading(true);
      const data = await dispatch(removeFundsToUserByAdmin(payload));

      if (data.status === 200) {
        handleClose();
        dispatch(
          success(
            data?.data?.message ||
              MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDS_SUCCESS,
            MESSAGES.ACCOUNT.WALLET.STRIPE.FUNDS_REMOVED
          )
        );
      } else {
        dispatch(failure(data.data.message));
        setRespError(data.data.message);
      }
    } catch (error) {
      return error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setRespError("");
    }
  };

  const handleChangeInput = (value, tab) => {
    if (value.length > 8) return;
    if (typeof value == "string") {
      let valueToAsign = value.replaceAll("$", "").replaceAll(",", "");
      if (valueToAsign !== "" && !/^-?\d*\.?\d{0,2}$/.test(valueToAsign))
        return;
      valueToAsign = numberWithComma(valueToAsign);
      setFund(valueToAsign);
    } else {
      value = numberWithComma(value);
      setFund(value);
    }
    if (error && value >= 50) {
      setError("");
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    setRespError("");
    setFund("");
    setActiveTab(null);
    setError("");
    setReasonError("");
    setReason("");
  }, [open]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="removeFundsWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="removeFundsContent">
        <Box className="fundsHeader">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ACCOUNT.WALLET.STRIPE.REMOVE_FUNDS}
          </Typography>
        </Box>
        <Box className="removeFundsInput">
          <Box className="removeInput">
            <InputLabel className="fundsLabel">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.DECREASE_WALLET_BALANCE_BY}:
            </InputLabel>
            <Input
              className="fundsInputField"
              type="text"
              placeholder="$10,000.00"
              value={fund ? `$${fund}` : fund}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
            {error && <Typography className="errorMessage">{error}</Typography>}
          </Box>
          <Box className="removeInput">
            <InputLabel className="fundsLabel">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.REASON_FOR_DECREASE}:
            </InputLabel>
            <Input
              className="fundsInputField"
              type="text"
              placeholder="Reason for this decrease"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            {reasonError && (
              <Typography className="errorMessage">{reasonError}</Typography>
            )}
          </Box>
        </Box>
        <Box className="removeFundsBtns">
          <Button onClick={handleClose}>
            {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
          </Button>
          {loading ? (
            <Box className="removeFundsLoader">
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            </Box>
          ) : (
            <Button onClick={handleRemoveFunding}>
              {MESSAGES.ACCOUNT.WALLET.STRIPE.REMOVE_FUNDS_CONFIRM}
            </Button>
          )}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default RemoveFundsByAdmin;
