import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { fetchContactImports } from "~/redux/actions/contact-import-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";

// Component
import ContactImportTable from "./ImportTable";

// MUI Components
import { Box, Button, Typography } from "@mui/material";

// Icons
import { ImportLightCIcon } from "~/assets/images";

// Styles
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const Import = () => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    let payload = {
      page: pagination?.page || DEFAULT_PAGINATION.page,
      pageSize:  pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
    };
    dispatch(fetchContactImports(payload));
  }, [pagination.page, pagination.pageSize]);

  return (
    <>
    <Box className="importWrapper">
      <Box
        className="headerWrap"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box className="ImportsRow">
          <Typography variant="h4">{MESSAGES.ACCOUNT.CONTACT_IMPORT.TITLE}</Typography>
        </Box>
        {(
          <Box className="inviteBtn">
            <Button
              sx={{ "&:hover": { backgroundColor: "#ed5c2f" } }}
              onClick={() => {
                navigate("/contacts/import");
              }}
            >
              <img src={ImportLightCIcon} alt="trash" />
              <Typography>{MESSAGES.CONTACTS.NO_CONTACTS_SCREEN.IMPORT_BTN_TEXT}</Typography>
            </Button>
          </Box>
        )}
      </Box>
      <ContactImportTable pagination={pagination} updatePagination={setPagination} />
      </Box>
    </>
  );
};

export default Import;
