import React, { useEffect, useState, useRef } from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { multiPageTemplates } from "~/utils/template-builder";
import { CONTACTS_OPTION_TYPE, CREATE_ORDER_TABS } from "~/utils/constants";
import { addDays } from "date-fns";
import { MESSAGES } from "~/utils/message";
import { trimSpaces } from "~/utils/helperFunctions";
// Actions
import { creatOrder, fetchOrderCost } from "~/redux/actions/orders-actions";
import {
  getAllTemplates,
  getProductDetails,
} from "~/redux/actions/template-builder";
import {
  fetchContactCount,
  fetchContactLabels,
  fetchContacts,
  fetchExpectedDeliveryDate,
  fetchReturnAddresses,
} from "../../../redux/actions/contacts-actions";
import { failure, success } from "../../../redux/actions/snackbar-actions";

// Components
import ReviewOrder from "./ReviewOrder";
import NewContact from "./NewContact";
import SubmitModal from "./SubmitModal";
import MailerDetails from "./MailerDetails";
import AddContacts from "./AddContacts";
import OrderDetails from "./OrderDetails";
import CostDetails from "./CostDetails";

// MUI Components
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";

// Icons
import { PlusIconCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const CreateOrder = () => {
  const [activeItemId, setActiveItemId] = useState(1);
  const [createContactModal, setCreateContactModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [modalType, setModalType] = useState("submit");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedPostage, setSelectedPostage] = useState(null);
  const [selectedEnvelope, setSelectedEnvelope] = useState(null);
  const [contactType, setContactType] = useState("Single");
  const [tagsOptions, setTagsOption] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactCount, setContactCount] = useState(null);
  const [expectedDate, setExpectedDate] = useState(null);
  const [selectedReturnedAddress, setSelectedReturnedAddress] = useState(null);
  const [uploadProcess, setUploadProcess] = useState(false);
  const [search, setSearch] = useState("");
  const [isFlipped, setFlipped] = useState(false);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(addDays(new Date(), 1));
  const [costDetails, setCostDetails] = useState(null);
  const [costLoader, setCostLoader] = useState(null);
  const [checkedIds, setCheckedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderSubmitLoader, setOrderSubmitLoader] = useState(false);
  const [orderProofDownloaded, setOrderProofDownloaded] = useState(false);
  const [orderName, setOrderName] = useState("");
  const [offerPercentageValue, setOfferPercentageValue] = useState(null);
  const [importedErrorRecordCount, setImportedErrorRecordCount] = useState(0);
  const [errors, setErrors] = useState({
    template: false,
    postage: false,
    envelope: false,
    contacts: false,
    returnAddress: false,
    date: false,
    review: false,
    offerPercentage: false,
  });
  const [hasInsufficientFunds, setHasInsufficientFunds] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const datepickerRef = useRef(null);

  let defaultPagination = {
    page: 1,
    pageSize: 10,
  };

  const products = useSelector((state) => state.productReducers.products);

  const templates = useSelector(
    (state) => state.templateReducer.templates.rows
  );

  const isScheduledMail = useSelector(
    (state) =>
      state?.userReducers?.user?.subscribedPlan?.meta?.stripeMeta?.metadata
        ?.general_scheduled_mail
  );

  const isScheduledMailDisabled = () => {
    if (!isScheduledMail) return true;
    try {
      const parsedScheduledMail = JSON.parse(isScheduledMail);
      return parsedScheduledMail?.limit == 0;
    } catch (error) {
      return true;
    }
  };

  const templateOptions = templates
    .map((template) => {
      return {
        id: template.id,
        label: `ID:${template.id} ${template.title}`,
        productType: template.product.productType,
        postageType: template.product.deliveryType,
        envelopeType: template.envelopeType,
        postCardSize: template.product.paperSize,
        frontThumbnailUrl: template.thumbnailUrl,
        backThumbnailUrl: template.backThumbnailUrl
          ? template.backThumbnailUrl
          : template.thumbnailUrl,
        templateType: template.templateType,
        meta: template.meta,
      };
    })
    .concat({
      id: "-1",
      label: "Create New Template",
      custom: true,
      icon: PlusIconCIcon,
      func: () => navigate("/create-template"),
    });

  const deliveryOptions = useSelector(
    (state) => state.templateReducer.productDetailByTemplate.deliveryDuration
  );

  const postageOptions =
    useSelector(
      (state) => state.templateReducer.productDetailByTemplate.deliveryType
    )
      ?.map((types, id) => {
        return {
          id,
          label: types + " " + deliveryOptions[types],
          value: types,
        };
      })
      .filter((postage) => {
        if (selectedTemplate && selectedTemplate?.productType === "Postcards") {
          return (
            (selectedTemplate.postCardSize === "6x11" &&
              postage.value !== "First Class") ||
            (selectedTemplate.postCardSize !== "6x11" &&
              postage.value !== "Standard Class")
          );
        }
        return true;
      }) || [];

  const evelopeOptions =
    useSelector(
      (state) => state.templateReducer.productDetailByTemplate.envelopeType
    )?.map((types, id) => {
      return {
        id,
        label: types,
      };
    }) || [];

  const contactOptions = useSelector((state) => state.contactsReducers.contacts)
    ?.map((contact) => {
      return {
        id: contact.id,
        label: `${contact.fullName}, ${contact.address1} ${
          contact?.address2 || ""
        } ${contact?.city || ""}, ${contact?.state || ""} ${
          contact?.zip || ""
        }`,
      };
    })
    .concat({
      id: "-1",
      label: "Create New Contact",
      custom: true,
      icon: PlusIconCIcon,
      func: () => setCreateContactModal(true),
    });

  contactOptions.unshift({
    id: "-100",
    label: "Type above to search for contacts",
    customPlaceholder: true,
  });

  const returnOptions = useSelector(
    (state) => state.contactsReducers.returnAddresses
  )
    ?.map((contact) => {
      return {
        id: contact.id,
        label: `${contact.fullName}, ${contact.address1} ${
          contact?.address2 || ""
        } ${contact?.city || ""}, ${contact?.state || ""} ${
          contact?.zip || ""
        }`,
      };
    })
    .concat(
      {
        id: "-1",
        label: "-No Return Address-",
        custom: true,
      },
      {
        id: "-2",
        label: "Create New Contact",
        custom: true,
        icon: PlusIconCIcon,
        func: () => setCreateContactModal(true),
      }
    );

  returnOptions.unshift({
    id: "-100",
    label: "Type above to search for contacts",
    customPlaceholder: true,
  });

  const tags = useSelector((state) => state.contactsReducers.labels);

  // Sort the tags alphabetically
  const sortedTags = [...tags].sort((a, b) => a?.title.localeCompare(b?.title));

  const defaultTagsOptions = sortedTags?.map((tag) => {
    return {
      id: tag.id,
      label: tag.title,
    };
  });

  const scheduleOptions = [
    {
      id: 1,
      label: "-ASAP-",
      value: "ASAP",
      custom: true,
    },
    {
      id: 2,
      label: "-Select Date-",
      func: () => setIsDatepickerOpen(true),
      custom: true,
      customPlaceholder: isScheduledMailDisabled(),
    },
  ];

  const handleClickOutside = (event) => {
    if (
      (datepickerRef.current &&
        !event.target.contains(datepickerRef.current) &&
        !event.target.closest(".react-datepicker") &&
        datepickerRef.current) ||
      datepickerRef.current === event.target
    ) {
      setIsDatepickerOpen(false);
    }
  };

  const searching = async () => {
    setLoading(true);
    if (+activeItemId === 1) {
      await dispatch(
        getAllTemplates(
          defaultPagination.page,
          defaultPagination.pageSize,
          search?.includes("ID:") ? search?.replace(/ID:\d+\s*/, "") : search
        )
      );
    } else if (contactType === "Multiple" && +activeItemId === 2) {
      if (!search) {
        setTagsOption(
          defaultTagsOptions?.concat({
            id: "-1",
            label: "-Select All Contacts-",
            custom: true,
          })
        );
        setLoading(false);
        return;
      }
      let filteredTags = defaultTagsOptions
        ?.filter((tag) =>
          tag?.label?.toLowerCase().includes(search?.toLowerCase())
        )
        .concat({
          id: "-1",
          label: "-Select All Contacts-",
          custom: true,
        });
      setTagsOption(filteredTags);
    } else {
      await dispatch(
        +activeItemId === 3
          ? fetchReturnAddresses({
              pagination: defaultPagination,
              search: search?.replace(/^(\S+\s+\S+)\s*(.*)/, "$1").trim(),
              isReturnAddress: true,
            })
          : fetchContacts({
              pagination: defaultPagination,
              search: search?.replace(/^(\S+\s+\S+)\s*(.*)/, "$1").trim(),
              isReturnAddress: false,
            })
      );
    }
    setLoading(false);
  };

  const switchTab = (pointer = null) => {
    if (pointer && pointer < activeItemId) {
      return setActiveItemId(pointer);
    }
    if (+activeItemId === 1) {
      if (!selectedTemplate)
        return setErrors((prev) => ({ ...prev, template: true }));
      if (!selectedPostage)
        return setErrors((prev) => ({ ...prev, postage: true }));
      if (selectedTemplate?.meta?.rosEnabled === true && !offerPercentageValue)
        return setErrors((prev) => ({ ...prev, offerPercentage: true }));
      if (
        !selectedEnvelope &&
        !multiPageTemplates.includes(selectedTemplate?.productType)
      )
        return setErrors((prev) => ({ ...prev, envelope: true }));
    }
    if (+activeItemId === 2 || pointer > 2) {
      if (!selectedContact) {
        return setErrors((prev) => ({ ...prev, contacts: true }));
      }
    }
    if (+activeItemId === 3 || pointer > 3) {
      if (!selectedReturnedAddress || !selectedDate)
        return setErrors((prev) => ({
          ...prev,
          returnAddress: !selectedReturnedAddress,
          date: !selectedDate,
        }));
    }
    setActiveItemId((prev) => (pointer ? pointer : prev + 1));
  };

  const redirectToOrders = () => navigate("/orders");

  const handleSubmitClose = (event, reason) => {
    if (modalType === "submit" && reason === "backdropClick") {
      return;
    }
    setActionModal(false);
  };

  const handleSubmitOpen = () => {
    if (checkedIds.length < 5) {
      setErrors((prev) => ({
        ...prev,
        review: true,
      }));
      return;
    }
    const isProductionMode = import.meta.env.VITE_APP_ENV === "production";
    if (isProductionMode && !orderProofDownloaded) {
      dispatch(
        failure(MESSAGES.ORDERS.CREATE.REVIEW.DOWNLOAD_PROOF_VALIDATION)
      );
      return;
    }
    submitOrder();
  };

  const handleNotSubmitOpen = () => {
    setModalType("cancel");
    setActionModal(true);
  };

  const getExpectedDeliveryDate = async () => {
    const response = await dispatch(
      fetchExpectedDeliveryDate({
        scheduledDate:
          typeof selectedDate === "object" ? selectedDate?.value : selectedDate,
      })
    );
    if (response.status === 200) {
      setExpectedDate(response.data.data.mailedDate);
    }
  };

  const getCountForTags = async () => {
    const response = await dispatch(
      fetchContactCount({ tag: selectedContact.id })
    );
    if (response.status === 200) {
      setContactCount(response.data.data.contactsCount);
    }
  };

  const getOrderCost = async () => {
    try {
      setCostLoader(true);
      let key = CONTACTS_OPTION_TYPE[contactType];
      let payload = {
        templateId: selectedTemplate?.id,
        productId: products.find(
          (product) =>
            product.productType === selectedTemplate?.productType &&
            product.deliveryType === selectedPostage?.value &&
            product.paperSize === selectedTemplate.postCardSize &&
            (selectedEnvelope
              ? product.envelopeType === selectedEnvelope.label
              : true)
        )?.id,
        [key]: key === "reqId" ? selectedContact : selectedContact.id,
      };

      const response = await dispatch(fetchOrderCost(payload));
      if (response.status === 200) {
        setCostDetails(response.data.data.cost);
      } else {
        dispatch(failure(response.data.message));
      }
      setCostLoader(false);
    } catch (error) {
      setCostLoader(false);
    }
  };

  const submitOrder = async () => {
    try {
      if (costDetails.deliverableContacts <= 0) {
        return dispatch(failure(MESSAGES.ORDERS.CREATE.DELIVERABLES_REQUIRED));
      }
      setOrderSubmitLoader(true);
      let key =
        CONTACTS_OPTION_TYPE[
          contactType === "Single" ? "SingleArray" : contactType
        ];
      const payload = {
        productId: products.find(
          (product) =>
            product.productType === selectedTemplate?.productType &&
            product.deliveryType === selectedPostage?.value &&
            product.paperSize === selectedTemplate.postCardSize &&
            (selectedEnvelope
              ? product.envelopeType === selectedEnvelope.label
              : true)
        )?.id,
        templateId: selectedTemplate?.id,
        scheduledDate:
          typeof selectedDate === "object" ? selectedDate?.value : selectedDate,
        name: trimSpaces(orderName),
        rosOfferPercentage: offerPercentageValue,
        errorRecordCount: importedErrorRecordCount,
        returnAddress:
          selectedReturnedAddress.id === "-1"
            ? null
            : selectedReturnedAddress.id,
        [key]:
          key === "reqId"
            ? selectedContact
            : key === "contactIds"
            ? selectedContact.id.split()
            : selectedContact.id,
      };
      const response = await dispatch(creatOrder(payload));
      if (response.status === 200) {
        if (response.data?.data?.hasInsufficientFunds) {
          setHasInsufficientFunds(true);
        }
        setModalType("submit");
        setActionModal(true);
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(
        failure(error?.response?.data?.message || MESSAGES.GENERAL_ERROR)
      );
    } finally {
      setOrderSubmitLoader(false);
    }
  };

  useEffect(() => {
    dispatch(
      getAllTemplates(defaultPagination.page, defaultPagination.pageSize)
    );
    dispatch(
      fetchContacts({ pagination: defaultPagination, isReturnAddress: false })
    );
    dispatch(
      fetchReturnAddresses({
        pagination: defaultPagination,
        isReturnAddress: true,
      })
    );
    dispatch(fetchContactLabels());

    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    const addBeforeUnloadListener = () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("beforeunload", handleBeforeUnload);
    };

    const removeBeforeUnloadListener = () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    addBeforeUnloadListener();

    return () => {
      removeBeforeUnloadListener();
    };
  }, []);

  useEffect(() => {
    searching();
  }, [search]);

  useEffect(() => {
    if (selectedDate) {
      getExpectedDeliveryDate();
    } else {
      setExpectedDate(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTemplate?.id) {
      dispatch(getProductDetails({ templateId: selectedTemplate.id }));
      setOfferPercentageValue(selectedTemplate?.meta?.rosOfferPercentage);
      setSelectedPostage(null);
      setSelectedEnvelope(null);
    }

    if (!selectedTemplate) {
      dispatch(
        getAllTemplates(defaultPagination.page, defaultPagination.pageSize)
      );
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (
      +activeItemId === 1 &&
      selectedPostage === null &&
      postageOptions.length &&
      selectedTemplate &&
      selectedTemplate?.productType === "Postcards"
    ) {
      setSelectedPostage(
        postageOptions.find(
          (postage) => postage?.value === selectedTemplate?.postageType
        ) || null
      );
    }
  }, [selectedTemplate, postageOptions]);

  useEffect(() => {
    if (
      +activeItemId === 1 &&
      selectedEnvelope === null &&
      evelopeOptions.length &&
      selectedTemplate &&
      selectedTemplate?.productType === "Professional Letters"
    ) {
      setSelectedEnvelope(
        evelopeOptions.find(
          (envelope) => envelope?.label === selectedTemplate?.envelopeType
        ) || null
      );
    }
  }, [selectedTemplate, evelopeOptions]);

  useEffect(() => {
    if (!selectedReturnedAddress && +activeItemId === 3) {
      dispatch(fetchReturnAddresses({ pagination: defaultPagination, isReturnAddress: true }));
    }
  }, [selectedReturnedAddress]);

  useEffect(() => {
    selectedContact instanceof Object && setSelectedContact(null);
    if (contactType === "Multiple") {
      setTagsOption(
        defaultTagsOptions.concat({
          id: "-1",
          label: "-Select All Contacts-",
          custom: true,
        })
      );
    }
  }, [contactType]);

  useEffect(() => {
    if (+activeItemId === 3) {
      dispatch(fetchContacts({ pagination: defaultPagination }));
    }
    if (+activeItemId === 4) {
      getOrderCost();
    }
  }, [activeItemId]);

  useEffect(() => {
    if (selectedContact && contactType === "Multiple") {
      getCountForTags();
    }
  }, [selectedContact]);

  useEffect(() => {
    if (isDatepickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    const body = document.getElementById("dashboard");
    if (body) {
      body.style.margin = "105px 0px 75px";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (body) {
        body.style.margin = "120px 0px 0px";
      }
    };
  }, [isDatepickerOpen]);

  return (
    <Box>
      <Container maxWidth="xxl">
        <Box className="createOrder">
          <Typography>{MESSAGES.ORDERS.CREATE.TITLE}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={8} md={12} sm={12}>
            <Box className="ordersFlow">
              {CREATE_ORDER_TABS.map((content) => {
                return (
                  <Box
                    key={content.id}
                    className={
                      content.id === activeItemId
                        ? `activeFlow flowContent`
                        : `flowContent`
                    }
                    onClick={() => switchTab(content.id)}
                  >
                    <img src={content.image} alt={content.name} />
                    <Typography>{content.name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          {+activeItemId === 4 ? (
            <CostDetails costDetails={costDetails} loading={costLoader} />
          ) : (
            ""
          )}
        </Grid>
        <Divider className="orderDivider" />
        <Grid container spacing={2}>
          {+activeItemId === 1 ? (
            <MailerDetails
              templateOptions={templateOptions}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate}
              postageOptions={postageOptions}
              setSelectedPostage={setSelectedPostage}
              selectedPostage={selectedPostage}
              evelopeOptions={evelopeOptions}
              setSelectedEnvelope={setSelectedEnvelope}
              selectedEnvelope={selectedEnvelope}
              isFlipped={isFlipped}
              setFlipped={setFlipped}
              setSearch={setSearch}
              setErrors={setErrors}
              loading={loading}
              errors={errors}
              htmlBox={false}
              offerPercentageValue={offerPercentageValue}
              setOfferPercentageValue={setOfferPercentageValue}
            />
          ) : +activeItemId === 2 ? (
            <AddContacts
              contactType={contactType}
              setContactType={setContactType}
              contactOptions={contactOptions}
              setSelectedContact={setSelectedContact}
              selectedContact={selectedContact}
              contactCount={contactCount}
              tagsOptions={tagsOptions}
              setUploadProcess={setUploadProcess}
              cancelOrder={handleNotSubmitOpen}
              setSearch={setSearch}
              setErrors={setErrors}
              switchTab={switchTab}
              activeItemId={activeItemId}
              loading={loading}
              errors={errors}
              setImportedErrorRecordCount={setImportedErrorRecordCount}
            />
          ) : +activeItemId === 3 ? (
            <OrderDetails
              returnOptions={returnOptions}
              selectedReturnedAddress={selectedReturnedAddress}
              setSelectedReturnedAddress={setSelectedReturnedAddress}
              scheduleOptions={scheduleOptions}
              selectedDate={selectedDate}
              expectedDate={expectedDate}
              setSelectedDate={setSelectedDate}
              isDatepickerOpen={isDatepickerOpen}
              datepickerRef={datepickerRef}
              startDate={startDate}
              setStartDate={setStartDate}
              setIsDatepickerOpen={setIsDatepickerOpen}
              setSearch={setSearch}
              setErrors={setErrors}
              loading={loading}
              errors={errors}
              orderName={orderName}
              setOrderName={setOrderName}
            />
          ) : +activeItemId === 4 ? (
            <ReviewOrder
              forntImage={selectedTemplate?.frontThumbnailUrl}
              backImage={selectedTemplate?.backThumbnailUrl}
              template={selectedTemplate}
              selectedReturnedAddress={selectedReturnedAddress}
              contactType={contactType}
              selectedContact={selectedContact}
              deliverable={costDetails?.deliverableContacts || 0}
              updateErrors={setErrors}
              errors={errors.review}
              name={"review"}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              htmlBox={true}
              isTemplateAvailable={true}
              setOrderProofDownloaded={setOrderProofDownloaded}
            />
          ) : (
            ""
          )}
        </Grid>
      </Container>
      {!uploadProcess && (
        <Box className="orderFooterWrapper">
          {+activeItemId === 4 && (
            <Typography>{MESSAGES.ORDERS.CREATE.REVIEW.DESCRIPTION}</Typography>
          )}
          <Button
            className="backBtn"
            disabled={activeItemId == 1}
            onClick={() =>
              activeItemId > 1 ? switchTab(activeItemId - 1) : undefined
            }
          >
            {MESSAGES.ORDERS.CREATE.BACK_BUTTON}
          </Button>
          <Box className="btnOuter">
            <Button className="cancelBtn" onClick={handleNotSubmitOpen}>
              {MESSAGES.ORDERS.CREATE.CANCEL_BUTTON}
            </Button>
            <Button
              className={+activeItemId === 4 ? `submitBtn` : `nextBtn`}
              onClick={() =>
                activeItemId < 4 ? switchTab(null) : handleSubmitOpen()
              }
              disabled={orderSubmitLoader}
            >
              {orderSubmitLoader ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : +activeItemId === 4 ? (
                MESSAGES.ORDERS.CREATE.SUBMIT_BUTTON
              ) : (
                MESSAGES.ORDERS.CREATE.NEXT_BUTTON
              )}
            </Button>
          </Box>
        </Box>
      )}
      <NewContact
        open={createContactModal}
        handleClose={() => setCreateContactModal(false)}
        setSelectedContact={
          +activeItemId === 2 ? setSelectedContact : setSelectedReturnedAddress
        }
        isReturnAddress={+activeItemId === 3}
      />
      <SubmitModal
        open={actionModal}
        handleClose={handleSubmitClose}
        onSubmit={redirectToOrders}
        modalType={modalType}
        contactType={contactType}
        deliverable={costDetails?.deliverableContacts || 0}
        hasInsufficientFunds={hasInsufficientFunds}
      />
    </Box>
  );
};

export default CreateOrder;
