import { combineReducers } from "redux";
import { snackbarReducers } from "./snackbar";
import { userReducers } from "./user";
import { contactsReducers } from "./contacts";
import { templateReducer } from "./templateBuilder";
import { ordersReducers } from "./orders";
import { productReducers } from "./product";
import { apiKeysReducers } from "./apiKeys";
import { stripeReducers } from "./stripe";
import { customFieldsReducers } from "./customFields";
import { tagsReducers } from "./tags";
import { dashboardReducers } from "./dashboard";
import { webhooksReducers } from "./webhooks";
import { sourcesReducers } from "./sources";
import { generalSettingsReducers } from "./generalSetting";

// Admin Reducers
import { adminUsersReducers } from './adminUsers';
import { adminBatchesReducers } from './adminBatches';
import { adminOrdersReducers } from './adminOrders';
import { adminDashboardReducers } from './adminDashboard';
import { adminTemplatesReducers } from './adminTemplates';
import { adminQueriesReducers } from "./adminQueries";
import { contactImportsReducers } from "./contactImports";

const reducer = combineReducers({
  snackbarReducers,
  userReducers,
  contactsReducers,
  templateReducer,
  ordersReducers,
  productReducers,
  apiKeysReducers,
  stripeReducers,
  customFieldsReducers,
  tagsReducers,
  dashboardReducers,
  webhooksReducers,
  generalSettingsReducers,
  adminUsersReducers,
  adminBatchesReducers,
  adminOrdersReducers,
  adminDashboardReducers,
  adminTemplatesReducers,
  sourcesReducers,
  adminQueriesReducers,
  contactImportsReducers,
});

export default reducer;
