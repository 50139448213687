import React, { useMemo, useState, useRef, useEffect } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";
import { usePositionReorder } from "~/hooks/useDraggable";

// Actions
import { fetchTemplateCategories, sortTemplateCategory } from "~/redux/actions/admin-templates-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import DraggableList from "~/components/General/DraggableList";
import Loader from "~/components/General/Loader";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Divider } from "@mui/material";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DragAndSortCategories = ({ openModal, handleCloseModal, pagination, search }) => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const containerRef = useRef(null);

  const rows =
    useSelector((state) => state.adminTemplatesReducers.allTemplateCategories) ||
    [];

  const [updatedList, updatePosition, updateOrder, setOrder] = usePositionReorder(rows);

  const memoizedList = useMemo(() => updatedList, [updatedList]);

  const dispatch = useDispatch();

  const reArrangeCategories = async () => {
    try {
      setLoading(true);
      const payload = {
        categories: updatedList.map((category, index) => ({
          id: +category?.id,
          sortOrder: index,
        })),
      };
      const response = await dispatch(
        sortTemplateCategory(payload, pagination)
      );
      dispatch(success(response?.data?.message || response?.message));
      handleClose();
    } catch (error) {
      dispatch(
        failure(
          response?.data?.message || response?.message || MESSAGES.GENERAL_ERROR
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const getAllAdminCategories = async () => {
    setLoader(true);
    let payload = {
      pagination,
      search: search?.length ? search : null,
      getAll: true
    };
    await dispatch(fetchTemplateCategories(payload));
    setLoader(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseModal();
  }

  useEffect(() => {
    setOrder(rows);
  }, [openModal, rows]);

  useEffect(() => {
    if (openModal) {
      getAllAdminCategories();
      setOpen(true);
    } else {
      setOrder([]);
    }
  }, [openModal])

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="dragCategoryModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="crossIcon"
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        <img src={CrossModalCIcon} alt="crossIcon" />
      </IconButton>
  
      <DialogContent dividers className="dragModalContent">
        {rows.length <= 0 || loader ? <Loader /> :
          <>
            <Box className="dragHeadings">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.REARRANGE.TITLE}
              </Typography>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Box className="categoriesContainerWrapper">
              <ul className="categoriesContainer" ref={containerRef}>
                {memoizedList.map((item, index) => (
                  <DraggableList
                    key={item.id || index}
                    index={index}
                    updateOrder={updateOrder}
                    updatePosition={updatePosition}
                    item={item}
                    containerRef={containerRef}
                  />
                ))}
              </ul>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Box className="dragModalBtns">
              <Button onClick={handleClose}>
                {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.REARRANGE.CANCEL_BUTTON}
              </Button>
              <Button onClick={reArrangeCategories}>
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ADMIN_DASHBOARD.CATEGORIES.REARRANGE.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </>}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default React.memo(DragAndSortCategories);
