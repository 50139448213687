import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

// MUI Components
import { Box, Button, Container, Typography } from "@mui/material";

// Icons
import { NoContactCIcon, WarningCIcon } from "~/assets/images";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// styles
import "./styles.scss";

const NoOrdersScreen = ({handleCreateOrder, openCreateConfirm, setOpenCreateConfirm, confirmCreateOrder}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContactCIcon} alt="no-contacts" />
        <Typography>{MESSAGES.ORDERS.NO_ORDERS_SCREEN.TITLE}</Typography>
        <Button onClick={handleCreateOrder}>
          <AddCircleOutlineIcon />
          {MESSAGES.ORDERS.NO_ORDERS_SCREEN.CREATE_ORDER_BUTTON}
        </Button>
      </Box>
      {openCreateConfirm && (
        <ConfirmDialog
          dialog={{
            heading: "Create New Test Order",
            subMessage: "This order is being placed on Test Mode and will not mail. If you wish to  place an order on Live Mode, please switch to Live Mode.",
            successButtonName: "Continue anyway",
            icon: WarningCIcon,
            currentAction:"mode_modal"
          }}
          open={openCreateConfirm}
          handleClose={() => setOpenCreateConfirm(false)}
          handleOk={confirmCreateOrder}
        />
      )}
    </Container>
  );
};

export default NoOrdersScreen;
