import React, { useMemo, useState } from "react";

//  Third party libraries
import Papa from "papaparse";

// Hooks
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import request from "~/utils/request";
import { MESSAGES } from "~/utils/message";
import { dragDrop, SAMPLE_PROPERTY_ADDRESS_CSV } from "~/utils/constants";

// MUI Component
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Container, CircularProgress } from "@mui/material";

// Icons
import { FileAddCIcon, GreenCrossCIcon, GreenTickCIcon } from "~/assets/images";
import OfferImportMappingModal from "./OfferImportMappingModal";
import { isOfferGeneratorWebsite } from "~/utils/helperFunctions";

const OfferPercentage = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [mappingModal, setMappingModal] = useState(false);
  const [contactsMapping, setContactsMapping] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [errors, setErrors] = useState({ status: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [loadOffers, setLoadOffers] = useState(false);

  const isOfferGenerator = isOfferGeneratorWebsite();

  const dispatch = useDispatch();

  let isPropertyAddressEnabled = false;

  if (isOfferGenerator) {
    isPropertyAddressEnabled = true;
  } else {
    isPropertyAddressEnabled = useSelector(
      (state) =>
        state?.generalSettingsReducers?.generalSettings
          ?.propertyAddressStrictness === "Include" || false
    );
  }

  const onDropAccepted = (files) => {
    if (errors.status) {
      setErrors((prev) => ({
        ...prev,
        status: false,
      }));
    }
    setSelectedFile(files);
    setFileName(files[0].name);
  };

  const onDropRejected = () => {
    setErrors((prev) => ({
      ...prev,
      status: true,
      message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.INVALID_FILE,
    }));
  };

  const onFileRemove = async (notify = true) => {
    try {
      setSelectedFile([]);
      setFileName(null);
    } catch (error) {
      if (notify) {
        dispatch(failure(error?.response?.data?.message));
      }
      return error;
    }
  };

  const processCSV = async () => {
    if (selectedFile.length) {
      Papa.parse(selectedFile[0], {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: async function (results) {
          const rowsArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
          });

          const headerRow = rowsArray[0];
          const dataRows = results.data.slice(0, 2);
          if (!headerRow?.length || !dataRows?.length) {
            setErrors((prev) => ({
              ...prev,
              status: true,
              message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.EMPTY_FILE,
            }));
            return;
          }
          const headerOptions = [];
          headerRow.forEach((header) => {
            headerOptions.push({
              name: header,
              examples: dataRows.map((row) => row[header]),
            });
          });

          try {
            setLoading(true);
            const suggestionsData = await request.post(
              "contacts/import/getSuggestions",
              {
                headers: headerRow.toString(),
              }
            );
            const suggestion = [];
            suggestion.push(suggestionsData.data.data);
            const autoSuggestion = suggestion[0]["auto_suggested"];

            // Combine contact_fields and custom_fields
            const combinedFields = suggestion.map((item) => {
              return [
                ...item.contact_fields,
                ...item.custom_fields.map((field) => ({
                  label: field,
                  field: "",
                })),
              ];
            });

            // Filtering the fields to only include the property fields
            const mappedFileds = combinedFields[0]
              .filter((item) =>
                [
                  "Property Address",
                  "Property City",
                  "Property Zip Code",
                  "Property State",
                ].includes(item.label)
              )
              .map((item) => {
                const matchingKey = Object.entries(autoSuggestion).find(
                  ([key, value]) => value === item.field
                );
                return {
                  label: item.label,
                  dbColumn: item.field || item.label,
                  value: matchingKey?.length ? matchingKey[0] : "",
                  required: true,
                  error: false,
                  display: item.isPropertyField
                    ? item.isPropertyField && isPropertyAddressEnabled
                    : true,
                };
              })
              .filter((item) => item.display !== false);
            setMappingOptions(headerOptions);
            setContactsMapping(mappedFileds);
            setMappingModal(true);
          } catch (error) {
            dispatch(failure(error.response.data.message));
            return error;
          } finally {
            setLoading(false);
          }
        },
      });
    } else {
      setErrors((prev) => ({
        ...prev,
        status: true,
        message: MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.REQUIRED,
      }));
    }
  };

  const downloadSampleCSV = () => {
    const blob = new Blob([SAMPLE_PROPERTY_ADDRESS_CSV], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "text/csv": [".csv"] },
      onDropAccepted,
      onDropRejected,
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...dragDrop.baseStyle,
      ...(isFocused ? dragDrop.focusedStyle : {}),
      ...(isDragAccept ? dragDrop.acceptStyle : {}),
      ...(isDragReject ? dragDrop.rejectStyle : {}),
      ...(errors.status ? dragDrop.rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, errors]
  );

  return (
    <Box
      sx={
        isOfferGenerator
          ? {
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : {}
      }
    >
      <Container
        maxWidth="xxl"
        sx={{
          paddingLeft: "65px !important",
        }}
      >
        <Box
          className="importContactsWrapper"
          sx={
            isOfferGenerator
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {}
          }
        >
          <Typography
            sx={
              isOfferGenerator
                ? {
                    fontSize: "25px !important",
                    color: "#000000 !important",
                  }
                : {}
            }
          >
            {MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE}
          </Typography>
          <Typography className="offerPercentageDescription">
            {MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_DESCRIPTION}
          </Typography>
          <Box className="importFileWrapper">
            <Box className="fileBox" {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <img src={FileAddCIcon} alt="add" />
              <Typography>
                <span className="upload">
                  {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.TITLE}
                </span>
                {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.DRAG_TEXT}
                <br />
                <span className="files">
                  {MESSAGES.CONTACTS.IMPORT.UPLOAD_FILE.FILE_TYPE}
                </span>
              </Typography>
            </Box>

            {errors.status && (
              <Typography className="errors">
                <span>{errors.message}</span>
              </Typography>
            )}

            <Box className="info">
              <Typography className="sample">
                <span
                  onClick={downloadSampleCSV}
                  style={isOfferGenerator ? { color: "#000000" } : {}}
                >
                  {MESSAGES.CONTACTS.IMPORT.DOWNLOAD}
                </span>
                {MESSAGES.CONTACTS.IMPORT.SAMPLE_FILE}
              </Typography>
              {fileName && (
                <Box
                  className="addedFileName"
                  sx={
                    isOfferGenerator
                      ? {
                          maxWidth: "100% !important",
                        }
                      : {}
                  }
                >
                  <img src={GreenTickCIcon} alt="green" />
                  {fileName.length >= (isOfferGenerator ? 50 : 30) ? (
                    <Typography
                      sx={
                        isOfferGenerator
                          ? {
                              width: "100% !important",
                            }
                          : {}
                      }
                    >
                      {fileName.substring(0, isOfferGenerator ? 50 : 30) +
                        "...csv"}
                    </Typography>
                  ) : (
                    <Typography
                      sx={
                        isOfferGenerator
                          ? {
                              width: "100% !important",
                            }
                          : {}
                      }
                    >
                      {fileName}
                    </Typography>
                  )}
                  <img
                    src={GreenCrossCIcon}
                    alt="cross"
                    onClick={onFileRemove}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          className="importContactBtns"
          sx={
            isOfferGenerator
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }
              : {}
          }
        >
          <Button
            onClick={processCSV}
            sx={
              isOfferGenerator
                ? {
                    maxWidth: "250px !important",
                  }
                : {}
            }
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.CONTACTS.IMPORT.CONTINUE
            )}
          </Button>
        </Box>
      </Container>
      <OfferImportMappingModal
        open={mappingModal}
        handleClose={() => {
          setMappingModal(false);
        }}
        mappingFileds={contactsMapping}
        options={mappingOptions}
        updateMapping={setContactsMapping}
        selectedFile={selectedFile}
        offerPercentage={true}
        loadOffers={loadOffers}
        setLoadOffers={setLoadOffers}
      />
    </Box>
  );
};

export default OfferPercentage;
