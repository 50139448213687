import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import useScreenWidth from "~/hooks/useScreenWidth";
import { displayTooltip } from "~/hooks/displayTooltip";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import { stringLength } from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";
import ImpersonateModal from "~/components/AdminPortal/Users/ImpersonationModal";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  IconButton,
  Menu,
  Checkbox,
} from "@mui/material";

// Icons
import { UserCIcon, DisableCIcon, CheckmarkCIcon, NoContactCIcon, TransactionCIcon, RemoveFundsCIcon, AddFundsCIcon } from "~/assets/images";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";

// Styles
import "./styles.scss";

const UsersTable = ({
  pagination,
  updatePagination,
  setOpenActionModal,
  selectedUser,
  setSelectedUser,
  setOpenFundsModal,
  searchApplied,
  setSelectedRows,
  selectedRows,
  setOpenRemoveFundsModal,
  handleViewTransactions
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeRowId, setActiveRowId] = useState(null);
  const [openImpersonationModal, setImpersonationModal] = useState(false);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);

  const open = Boolean(anchorEl);
  const openActions = Boolean(actions);

  const pageSizes = DEFAULT_PAGE_SIZES;

  const fetchedRows = useSelector((state) => state.adminUsersReducers.users);
  const rowsCount = useSelector((state) => state.adminUsersReducers.rowsCount);
  const to = useSelector((state) => state.adminUsersReducers.to);
  const from = useSelector((state) => state.adminUsersReducers.from);
  const lastPage = useSelector(
    (state) => state.adminUsersReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminUsersReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminUsersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector((state) => state.adminUsersReducers.loading);

  const currentUser = useSelector((state) => state.userReducers.user);

  const isScreen = useScreenWidth();
  const isTooltip = displayTooltip(4000);

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    setSelectedRows(selectedRowIds);
  };

  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();

    // Update the selectedRow object directly in the rows array
    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id ? { ...row, checked: !row.checked } : row
    );

    // If the user selects all contacts and then removes a single contact, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.id === selectedRow.id
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );

      const filteredRowsIds = filteredRows
        .map((contact) => contact.checked === true && contact.id)
        .filter(Boolean);

      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.id)
      ? selectedRows.filter((id) => id !== selectedRow.id) // Remove the ID if already selected
      : [...selectedRows, selectedRow.id]; // Add the ID if not selected

    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);

    setRemoveBulkSelect(true);
  };

  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };

  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [currentPage, perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
          >
            <MenuItem
              onClick={rowsCount > 0 ? selectAll : undefined}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_ALL_ITEMS} ({rowsCount})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.ADMIN_DASHBOARD.USERS.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "id",
      headerName: "User ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.id || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "ownerId",
      headerName: "Account ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.ownerId || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.company}`, 15) ? (
          <Tooltip title={params.row.company}>
            <Box className="addressWrapper">
              <Typography>{params.row.company || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.company || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "name",
      headerName: "User Name",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.name}`, 14) ? (
          <Tooltip title={params.row.name}>
            <Box className="addressWrapper">
              <Typography>{params.row.name || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.name || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.email}`, 13) && !isTooltip ? (
          <Tooltip title={params.row.email}>
            <Box className="emailWrapper">
              <Typography>{params.row.email || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="emailWrapper">
            <Typography>{params.row.email || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.role || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.plan}`, 20) ? (
          <Tooltip title={params.row.plan}>
            <Box className="addressWrapper">
              <Typography>{params.row.plan || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.plan || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "orders",
      headerName: "No of Orders",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.orders || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.revenue || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "signUpDate",
      headerName: "Sign Up Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.signUpDate || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Seen",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.updatedAt || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1600 ? 1 : 0,
      renderCell: (params) => (
        <>
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setActiveRowId(params.row); // Set active row ID when the menu is opened
                  setActions(event.currentTarget);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color:
                      activeRowId?.id === params.row.id && openActions
                        ? "#ed5c2f"
                        : "inherit",
                  }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={actions}
                open={openActions}
                onClose={() => setActions(null)}
                PaperProps={{
                  style: {
                    maxWidth: "150px",
                  },
                  className: "usersActionDropdown",
                }}
              >
                {activeRowId?.status !== "Invited" && (
                  <MenuItem
                    onClick={() => {
                      setSelectedUser(activeRowId);
                      setImpersonationModal(true);
                      setActions(null);
                    }}
                  >
                    <img src={UserCIcon} alt="impersonate" />
                    {MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.IMPERSONATE}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setSelectedUser(activeRowId);
                    setOpenFundsModal(true);
                    setActions(null);
                  }}
                >
                  <img src={AddFundsCIcon} alt="funds" />
                  {MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.ADD_FUNDS}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedUser(activeRowId);
                    setOpenRemoveFundsModal(true);
                    setActions(null);
                  }}
                >
                  <img src={RemoveFundsCIcon} alt="remove-funds" />
                  {MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.REMOVE_FUNDS}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedUser(activeRowId);
                    setOpenActionModal(true);
                    setActions(null);
                  }}
                >
                  <img
                    src={
                      activeRowId?.status === "Blocked"
                        ? CheckmarkCIcon
                        : DisableCIcon
                    }
                    alt="enable"
                  />
                  {activeRowId?.status === "Blocked"
                    ? MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.ENABLE_ACCOUNT
                    : MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.DISABLE_ACCOUNT}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleViewTransactions(activeRowId);
                    setActions(null);
                  }}
                >
                  <img src={TransactionCIcon} alt="view-transactions" />
                  {MESSAGES.ADMIN_DASHBOARD.USERS.USER_ACTIONS.VIEW_TRANSACTIONS}
                </MenuItem>
              </Menu>
            </Box>
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: "30px",
          width: "100%",
        }}
        className="userAdmin"
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={rowsCount}
          pagination={pagination}
          paginationMode="server"
          hideFooterSelectedRowCount
          hideFooterPagination
          rowSelection={false}
          getRowId={(row) => row.key}
          disableColumnMenu={true}
          className="userAdminTable"
          slots={{
            noRowsOverlay: () => (
              <Stack className="noRowsTextContacts">
                <img src={NoContactCIcon} alt="NoContact" />
                {`${MESSAGES.NO_RESULTS_TEXT} ${!searchApplied ? "filters" : "search"}`}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack className="noRowsTextContacts">
                <img src={NoContactCIcon} alt="NoContact" />
                {MESSAGES.NO_ROWS_TEXT}
              </Stack>
            ),
            loadingOverlay: Loader,
          }}
        />
        <Box className="paginationWrapper">
          <Pagination
            count={lastPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            page={currentPage}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIosNewIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
          <Select
            className={
              perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
            }
            value={perPage}
          >
            {pageSizes.map((pageSize) => {
              return (
                <MenuItem
                  key={pageSize}
                  value={pageSize}
                  onClick={() => {
                    updatePagination({
                      page: 0,
                      pageSize: pageSize,
                    });
                  }}
                >
                  {pageSize}
                </MenuItem>
              );
            })}
          </Select>

          <Typography>
            Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to}{" "}
            of {rowsCount} results
          </Typography>
        </Box>
      </div>
      <ImpersonateModal
        open={openImpersonationModal}
        handleClose={() => setImpersonationModal(false)}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default UsersTable;
