import React from "react";

const contactImport = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <g clipPath="url(#clip0_1485_4556)">
        <path d="M7.82771 10.131C7.75734 10.1311 7.68765 10.1172 7.62263 10.0903C7.55761 10.0634 7.49853 10.024 7.44877 9.97421C7.39902 9.92446 7.35956 9.86538 7.33266 9.80035C7.30575 9.73533 7.29194 9.66565 7.29199 9.59528V1.58357C7.29199 1.44149 7.34843 1.30522 7.4489 1.20476C7.54936 1.10429 7.68563 1.04785 7.82771 1.04785C7.96979 1.04785 8.10605 1.10429 8.20651 1.20476C8.30698 1.30522 8.36342 1.44149 8.36342 1.58357V9.59528C8.36342 9.73736 8.30698 9.87362 8.20651 9.97409C8.10605 10.0746 7.96979 10.131 7.82771 10.131Z" fill="black"/>
        <path d="M7.82766 10.3721C7.64761 10.3724 7.46929 10.3371 7.30292 10.2683C7.13655 10.1994 6.98543 10.0984 6.85823 9.97098L3.96494 7.0777C3.86452 6.97728 3.80811 6.84107 3.80811 6.69906C3.80811 6.55704 3.86452 6.42084 3.96494 6.32041C4.06537 6.21999 4.20157 6.16357 4.34359 6.16357C4.48561 6.16357 4.62181 6.21999 4.72223 6.32041L7.61552 9.2137C7.64337 9.24156 7.67645 9.26366 7.71285 9.27874C7.74925 9.29382 7.78826 9.30158 7.82766 9.30158C7.86706 9.30158 7.90607 9.29382 7.94247 9.27874C7.97887 9.26366 8.01194 9.24156 8.0398 9.2137L10.9331 6.32041C11.0341 6.2228 11.1694 6.16876 11.3099 6.16994C11.4503 6.17113 11.5847 6.22743 11.6841 6.32673C11.7834 6.42602 11.8398 6.56037 11.8411 6.70083C11.8423 6.8413 11.7884 6.97663 11.6908 7.0777L8.79709 9.97098C8.66986 10.0984 8.51873 10.1994 8.35237 10.2682C8.18601 10.3371 8.0077 10.3724 7.82766 10.3721Z" fill="black"/>
        <path d="M13.8 15.2448H1.85486C1.47831 15.2443 1.11732 15.0946 0.851063 14.8283C0.584806 14.5621 0.435024 14.2011 0.43457 13.8245V10.0797C0.43457 9.93758 0.491011 9.80132 0.591477 9.70085C0.691943 9.60039 0.828204 9.54395 0.970285 9.54395C1.11236 9.54395 1.24863 9.60039 1.34909 9.70085C1.44956 9.80132 1.506 9.93758 1.506 10.0797V13.8245C1.50611 13.917 1.5429 14.0057 1.6083 14.0711C1.6737 14.1365 1.76237 14.1733 1.85486 14.1734H13.8C13.8925 14.1733 13.9812 14.1365 14.0466 14.0711C14.112 14.0057 14.1487 13.917 14.1489 13.8245V10.0797C14.1489 9.93758 14.2053 9.80132 14.3058 9.70085C14.4062 9.60039 14.5425 9.54395 14.6846 9.54395C14.8267 9.54395 14.9629 9.60039 15.0634 9.70085C15.1638 9.80132 15.2203 9.93758 15.2203 10.0797V13.8245C15.2198 14.2011 15.07 14.5621 14.8038 14.8283C14.5375 15.0946 14.1765 15.2443 13.8 15.2448Z" fill="black"/>
      </g>
      <defs>
      <clipPath id="clip0_1485_4556">
        <rect width="23" height="23" fill="white" transform="translate(0.0358887 0.914062)" />
      </clipPath>
      </defs>
    </svg>
  );
};

export default contactImport;
