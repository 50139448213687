import React, { useState } from "react";

// MUI Components
import {
  TextField,
  Autocomplete,
  Divider,
  Typography,
  Box,
  Checkbox,
  Chip,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from '@mui/icons-material/Close';

// styles
import "./styles.scss";

const GenericAutocomplete = (props) => {
  const {
    placeholder,
    options,
    selectedValue,
    setSelectedValue,
    search,
    updateErrors,
    name,
    loading,
    disable = false,
    multiple = false,
    disableClearable = false,
    templateBuilder= false,
    filterAutoComplete = false,
    error=false,
    tagsClass=false,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const updateValue = (event, newValue) => {
    event.preventDefault();
    !newValue?.func ? setSelectedValue(newValue) : undefined;
    updateErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
    newValue?.func ? newValue?.func() : undefined;
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      sx={{
        color: "#ED5C2F",
      }}
    />
  );

  const renderOption = (props, option, { selected }) => {
    return (
      <div key={option.id}>
        {loading && options.indexOf(option) === 0 ? (
          <Box>
            <LinearProgress
              sx={{
                backgroundColor: "white",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#ED5C2F",
                },
              }}
            />
          </Box>
        ) : null}
        <li
          {...props}
          key={option.id}
        >
          {option.custom ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {option.icon && <img src={option.icon} alt="icon" />}
              <Typography sx={{ color: option.custom ? "#ED5C2F" : "" }}>
                {option.label}
              </Typography>
            </Box>
          ) : multiple ? (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected || selectedValue?.includes(option.label)}
              />
              {option.label}
            </>
          ) : (
            <Typography className={templateBuilder && "tempBuilderOptions"}>
              {option.label}
            </Typography>
          )}
        </li>
        <Divider className="listDivider" />
      </div>
    );
  };

  return (
    <Autocomplete
      className={multiple ? `multipleAutoSelect ${tagsClass && "tagsUpdateSelect"}` : templateBuilder ? "tempAutocomplete" : `genericAutocomplete ${filterAutoComplete ? "filterAutoComplete" : ""} ${error ? "errorAutocomplete" : ""}`}
      multiple={multiple}
      renderTags={(value, getTagProps) =>
        tagsClass ? null : value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.label}
            label={option.label}
            deleteIcon={<HighlightOffIcon sx={{ color: "#ED5C2F" }} />}
          />
        ))
      }
      clearIcon={tagsClass ? null : <CloseIcon />}
      limitTags={multiple && tagsClass ? 3 : multiple ? 1 : undefined}
      // disablePortal
      disabled={disable}
      disableCloseOnSelect={multiple}
      disableClearable={disableClearable}
      id="combo-box-demo"
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      value={selectedValue}
      loading={loading}
      onChange={(event, newValue) => {
        updateValue(event, newValue);
        !multiple
          ? search(newValue?.label ? newValue?.label : newValue)
          : undefined;
      }}
      onKeyUp={(event) => search(event.target.value)}
      onBlur={(event) => search(event.target.value || "")}
      onFocus={(event) => search(event.target.value || "")}
      options={options}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      popupIcon={
        <KeyboardArrowDownIcon
          sx={{
            fill: disable
              ? "rgba(0, 0, 0, 0.38)"
              : menuOpen
              ? "#ED5C2F"
              : "inherit",
            transform: "none",
          }}
        />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedValue?.length > 0 && tagsClass ? placeholder : selectedValue?.length > 0 ? "" : placeholder}
          size="small"
          className={
            templateBuilder ? "tempAutoField" : ""
          }
        />
      )}
      renderOption={renderOption}
      classes={{
        paper: tagsClass && "tagsPaper"
      }}
      getOptionDisabled={(option) => option.customPlaceholder}
      ListboxProps={{ style: { maxHeight: 250 } }}
    />
  );
};

export default GenericAutocomplete;
