import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "~/components/General/Loader";
import { dateFormat } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Stack,
  Tooltip,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./styles.scss";
import { displayTooltip } from "~/hooks/displayTooltip";
import { DEFAULT_PAGE_SIZES } from "~/utils/constants";
import { downloadFile } from "~/utils/helperFunctions";

const ContactImportTable = ({ pagination, updatePagination }) => {
  const { contactImports: rows = [], to, from, lastPage = 1, currentPage = 1, loading: isLoading, rowsCount = 0 } =
    useSelector((state) => state.contactImportsReducers);
  const perPage = useSelector((state) => state.contactImportsReducers.perPage) || DEFAULT_PAGE_SIZES[0];
  const isTooltip = displayTooltip();

    const [downloading, setDownloading] = useState({
      status: false,
      logId: null,
    });

  const handleChangePage = useCallback((event, newPage) => {
    updatePagination({ page: newPage, pageSize: pagination.pageSize });
  }, [pagination.pageSize, updatePagination]);

  const handleChangePageSize = useCallback((event) => {
    updatePagination({ page: 0, pageSize: event.target.value });
  }, [updatePagination]);

    const downloadCSV = async (csvFileUrl, csvFileName, logId) => {
      setDownloading({
        status: true,
        logId: logId,
      });
  
      await downloadFile(csvFileUrl, csvFileName);
  
      setDownloading({
        status: false,
        logId: null,
      });
    };

  const columns = useMemo(() => [
    {
      field: "importedBy",
      headerName: "Imported By",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.creator.fullName}</Typography>
        </Box>
      ),
    },
    {
      field: "importedDate",
      headerName: "Imported Date",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.createdAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "list",
      headerName: "List",
      width: 600,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Tooltip title={params.row.fileName} disableHoverListener={isTooltip} disableInteractive>
          <Box className="addressWrapper">
            {downloading.status &&
              downloading.logId === params?.row?.id ? (
                <CircularProgress
                  sx={{
                    color: "#ed5c2f",
                    width: "20px !important",
                    height: "20px !important",
                  }}
                />
              ) : (
                <Link className="link" onClick={() => { downloadCSV(params.row.fileUrl, params.row.fileName, params.row.id); }}>
                  <Typography>{params.row.fileName}</Typography>
                </Link>
              )}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => <Typography>{params.row.quantity}</Typography>,
    },
  ], [isTooltip]);

  return (
    <div className="importTableWrapper" style={{ marginTop: "25px", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={DEFAULT_PAGE_SIZES}
        rowCount={rowsCount}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu
        className="importTableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem slots={{ previous: ArrowBackIosNewIcon, next: ArrowForwardIosIcon }} {...item} />
          )}
        />
        <Select className={perPage >= 100 ? "pageSelect pageSelectChange" : "pageSelect"} value={perPage} onChange={handleChangePageSize}>
          {DEFAULT_PAGE_SIZES.map((size) => (
            <MenuItem key={size} value={size}>{size}</MenuItem>
          ))}
        </Select>
        <Typography>
          Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to} of {rowsCount} results
        </Typography>
      </Box>
    </div>
  );
};

export default ContactImportTable;
